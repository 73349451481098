import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AlbumIcon from '@mui/icons-material/Album';
import ArticleIcon from '@mui/icons-material/Article';
import HomeIcon from '@mui/icons-material/Home';
import LockIcon from '@mui/icons-material/Lock';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import PersonIcon from '@mui/icons-material/Person';
import { AppBar, Avatar, IconButton, Link, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { Box } from '@mui/system';
import { NavLink } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";
import { useUser } from "../../store/context/UserContext";
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { formatUserImage } from '../../utils';

const drawerWidth = 250;

const Navigation = () => {
    const { isAuthenticated, signOut } = useAuth();
    const { state } = useUser();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const username = state.data?.name ? state.data.name : state.data?.firstName && state.data?.lastName ? `${state.data.firstName} ${state.data.lastName}` : null;

    let image = state.data?.image;
    if (state.data?.originalImageUrl) image = formatUserImage(state.data.id);

    const drawer = (
        <>
            <Toolbar disableGutters sx={{ mx: 2 }}>
                <Box display="flex" alignItems="center">
                    <Avatar alt={username ?? undefined} src={image ?? undefined} />
                    <Box sx={{ ml: 2 }}><Typography variant="body2"
                        fontWeight="bold">{username ?? state?.data?.email}</Typography></Box>
                </Box>
            </Toolbar>
            <Divider />
            <List>
                <NavLink to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem button key="Home">
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItem>
                </NavLink>
                <NavLink to="/wallet" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem button key="Wallet">
                        <ListItemIcon>
                            <AccountBalanceIcon />
                        </ListItemIcon>
                        <ListItemText primary="Wallet" />
                    </ListItem>
                </NavLink>
                <NavLink to="/account" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem button key="Account">
                        <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary="Account" />
                    </ListItem>
                </NavLink>
                <NavLink to="/albums" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem button key="Albums">
                        <ListItemIcon>
                            <AlbumIcon />
                        </ListItemIcon>
                        <ListItemText primary="Albums" />
                    </ListItem>
                </NavLink>
                <NavLink to="/recordings" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem button key="Recodings">
                        <ListItemIcon>
                            <MusicNoteIcon />
                        </ListItemIcon>
                        <ListItemText primary="Recordings" />
                    </ListItem>
                </NavLink>
                <NavLink to="/terms" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem button key="Terms">
                        <ListItemIcon>
                            <ArticleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Terms" />
                    </ListItem>
                </NavLink>
                <Divider />
                {isAuthenticated() ? (
                    <ListItem button key="Logout" onClick={() => signOut()}>
                        <ListItemIcon>
                            <LockIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                ) : (
                    <NavLink to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ListItem button key="Login">
                            <ListItemIcon>
                                <LockIcon />
                            </ListItemIcon>
                            <ListItemText primary="Login" />
                        </ListItem>
                    </NavLink>
                )}
            </List>
            <Box mt="auto" p={2}>
                <Typography variant="body2" color="textSecondary">Questions? Issues? <br /></Typography>
                <Link href="mailto:info@fairmus.com" variant="body2" color="primary" style={{ textDecoration: 'none' }}>info@fairmus.com</Link>
            </Box>
        </>
    )

    return (
        <Box display="flex">
            <AppBar
                position="fixed"
                sx={{
                    width: { lg: `calc(100% - ${drawerWidth}px)` },
                    ml: { lg: `${drawerWidth}px` },
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    userSelect: 'none',
                    pointerEvents: 'none'
                }}
            >
                <Toolbar sx={{ display: { xs: 'flex', lg: 'none' }, justifyContent: 'flex-end' }}>
                    <IconButton
                        edge="end"
                        onClick={handleDrawerToggle}
                        sx={{ backgroundColor: '#000033', '&:hover': { backgroundColor: '#000033' }, pointerEvents: 'all' }}
                    >
                        <MenuIcon sx={{ color: '#FFF' }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true
                    }}
                    sx={{
                        display: { xs: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#fdfdfd' },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', lg: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#fdfdfd' },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    )
}

export default Navigation
