import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { IRecording } from '../../models/IRecording';

interface RecordingOptionType extends IRecording {
    inputValue: string;
}

interface RecordingAutocompleteFieldProps {
    value?: RecordingOptionType[] |null;
    label: string;
    error?: boolean;
    options: RecordingOptionType[];
    onValueChange: (value: IRecording[]) => void;
}

const SongAutocompleteField = ({ value: controlledValue, error, label, options, onValueChange }: RecordingAutocompleteFieldProps) => {
    const [value, setValue] = useState<RecordingOptionType[]>([]);

    // If we have set values from parent, set it to local state
    useEffect(() => {
        if (controlledValue && controlledValue.length > 0 && value.length <= 0) {
            setValue(controlledValue);
        }
    }, [controlledValue]);

    const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: (string | RecordingOptionType)[], reason: string) => {
        // If the user wants to clear the whole input field
        if (reason === 'clear') {
            setValue([]);
            return;
        }

        const [last] = Array.isArray(newValue) ? newValue.slice(-1) : [newValue];

        // If we are entering a new option by pressing the 'ENTER' key
        if (typeof last === 'string') {
            return;
        }

        // If we are removing a selected option
        if (reason === 'removeOption') {
            if (!newValue || typeof newValue === 'string' || !Array.isArray(newValue)) {
                setValue([]);
                return;
            }
            setValue(newValue as RecordingOptionType[]);
            return;
        }

        // If the clicked option already exists in 'options' we have to add it to 'value'
        if (options.find((o) => o.id === last.id)) {
            setValue((p) => [...p, last]);
            return;
        }
    }

    useEffect(() => onValueChange(value as IRecording[]), [value]);

    return (
        <React.Fragment>
            <Autocomplete
                value={value}
                onChange={handleChange}
                options={options}
                getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.inputValue;
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                multiple
                renderOption={(props, option) => (
                    <li {...props}>
                        {option.inputValue}
                    </li>
                )}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} error={error} label={label} />}
            />
        </React.Fragment>
    );
}

export default SongAutocompleteField;
