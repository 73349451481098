import { useState } from 'react'
import { getDocs, query, where } from "firebase/firestore";
import useFirestore from './useFirestore';
import { useUser } from '../store/context/UserContext';
import { IAlbum } from '../models/IAlbum';
import { AppActionType, useApp } from '../store/context/AppContext';

const useAlbums = () => {
    const [isFetching, setIsFetching] = useState(false);

    const { state, dispatch } = useApp();
    const { collectionRef, getById } = useFirestore();
    const { state: { data: user } } = useUser();

    const albums = state.albums ?? [];

    const fetchAlbums = async ({ force }: { force?: boolean } = {}) => {
        // If we already have albums, dont fetch again
        // We can bypass this check if we want to force a fetch
        if (!force && albums.length > 0) return albums;

        setIsFetching(true);
        const q = query(collectionRef('albums'), where('ownerId', '==', user?.id));
        const snapshot = await getDocs(q);

        const data: IAlbum[] = snapshot.docs.map((doc) => {
            return {
                ...doc.data() as IAlbum,
                id: doc.id,
            }
        });

        const sortedAlbums = data.sort((a, b) => b.createdAt.toDate().valueOf() - a.createdAt.toDate().valueOf());

        setIsFetching(false);
        dispatch({
            type: AppActionType.SET_ALBUMS,
            payload: sortedAlbums
        })
        return data;
    };

    const fetchAlbumById = async (id: string) => {
        // First lets check if we have it in store
        const album = albums.find((album) => album.id === id);
        if (album) return album;

        // If not, lets fetch it
        try {
            const doc = await getById('albums', id);
            const data = doc.data() as IAlbum;
            return {
                ...data,
                id: doc.id,
            };
        } catch (err) {
            console.error(`Fetching album by id failed`, err);
            return null;
        }
    }

    return { albums, fetchAlbums, fetchAlbumById, isFetching };
}

export default useAlbums;
