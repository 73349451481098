import { Chip, ChipProps, CircularProgress, IconButton, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/system';
import {useEffect, useRef, useState} from 'react';
import useWallet from '../../hooks/useWallet';
import { TargetType, TransactionStatus, TransactionType } from '../../models/ITransaction';
import { useUser } from '../../store/context/UserContext';
import DownloadIcon from '@mui/icons-material/Download';
import {CSVLink} from "react-csv";
import {getDocs, query, where} from "firebase/firestore";

const csvHeaders = ['songName', 'album', 'artists', 'periodFrom', 'periodTo', 'app', 'source', 'totalTimeListened', 'feeShare'];


const WalletHistoryTable = () => {
    const { transactions, fetchTransactions, fetchTransactionDetails, isFetching } = useWallet();
    const { state: { data: user } } = useUser();

    const csvLink = useRef();
    const [data, setData] = useState([]);
    const [periodFrom, setPeriodFrom] = useState('');

    useEffect(() => {
        fetchTransactions();
    }, []);

    const ownerTargetTransactions = transactions.filter((t) => t.target === TargetType.OWNER);

    const handleCsvExport = async (periodFrom) => {
        const fetchedData = await fetchTransactionDetails(periodFrom);
        setPeriodFrom(periodFrom);
        setData(fetchedData);
        csvLink.current.link.click();
    };

    const generateFileName = () => {
        return `Transactions-${periodFrom}___${new Date()?.toLocaleDateString()}.csv`;
    };

    if (isFetching) {
        return (
            <Box width="100%" height="100px" display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
            </Box>
        )
    }

    if ((!ownerTargetTransactions || ownerTargetTransactions.length === 0) && !isFetching) {
        return (
            <Box width="100%" height="100px" display="flex" alignItems="center">
                Transaction history is empty
            </Box>
        )
    }

    return (
        <>
            <CSVLink separator=";" data={data} headers={csvHeaders} filename={generateFileName()} ref={csvLink} />
            <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Recipient</TableCell>
                            <TableCell>IBAN</TableCell>
                            <TableCell>Period</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Export</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ownerTargetTransactions.map((row, i) => {
                            let chipColor: ChipProps['color'] = 'default';
                            if (row.status === TransactionStatus.Completed) chipColor = 'success';
                            if (row.status === TransactionStatus.Failed) chipColor = 'error';

                            const amountColor = row.type === TransactionType.ROYALTIES_IN ? '#2e7d32' : '#d32f2f';
                            const amount = row.amount.toFixed(2);

                            return (
                                <TableRow
                                    key={i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{row.createdAt.toDate().toLocaleString()}</TableCell>
                                    <TableCell>{row.type}</TableCell>
                                    <TableCell>{row.recipent ?? user?.name}</TableCell>
                                    <TableCell>{row.iban ?? '-'}</TableCell>
                                    <TableCell>{row.periodFrom && row.periodTo ? `${row.periodFrom} - ${row.periodTo}` : '-'}</TableCell>
                                    <TableCell>
                                        <Typography variant="body2" color={amountColor}>
                                            {row.type === TransactionType.ROYALTIES_OUT ? `-${amount}` : `+${amount}`}€
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Chip label={TransactionStatus[row.status]} variant="outlined" color={chipColor} size="small" />
                                    </TableCell>
                                    <TableCell>
                                        {row.type === TransactionType.ROYALTIES_IN && (
                                            <>
                                                <IconButton onClick={() => handleCsvExport(row.periodFrom)}>
                                                    <DownloadIcon />
                                                </IconButton>
                                            </>
                                        )}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>


    );
}

export default WalletHistoryTable;
