import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface RejectedError {
    code: string,
    message: string,
}

export interface RejectedFilesErrorResponse {
    errors: RejectedError[],
    file: File,
}

const MultipleAudioFileUpload = ({ maxSize, error, onChange }: { maxSize?: number, error?: boolean, onChange: (acceptedFiles: File[] | null) => void }) => {
    const [uploadedFiles, setUploadedFiles] = useState<File[] | null>(null);
    const [rejectedFiles, setRejectedFiles] = useState<RejectedFilesErrorResponse[] | null>(null);

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        setUploadedFiles(acceptedFiles);
        if (rejectedFiles && rejectedFiles.length > 0) setRejectedFiles(rejectedFiles);
        else setRejectedFiles(null);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'audio/*', maxSize: maxSize ?? Infinity });

    useEffect(() => onChange(uploadedFiles), [uploadedFiles])

    return (
        <>
            <div {...getRootProps()} style={{
                width: '100%',
                height: '200px',
                backgroundColor: '#FAFAFA',
                border: `${(uploadedFiles && !error) ? '0px' : '1px'} solid ${error ? 'red' : '#EAEAEA'}`,
                borderRadius: 8,
                padding: '16px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                flexDirection: 'column'
            }}>
                <input {...getInputProps()} multiple={true} />
                {
                    isDragActive ?
                        <Typography textAlign="center">Drop the audio files here ...</Typography>
                        : <Typography textAlign="center">Drag 'n' drop audio files here, or click to select audio files</Typography>

                }
            </div>
            {rejectedFiles && rejectedFiles.length > 0 && (
                <Box>
                    <Divider sx={{ my: 4 }} />
                    <Typography variant="body1" color="error">The following {rejectedFiles.length === 1 ? 'file exceeds' : 'files exceed'} the 1GB size limit:</Typography>
                    {rejectedFiles.map((response) => {
                        return <Typography variant="body2" key={response.file.name}>{response.file.name}</Typography>
                    })}
                </Box>
            )}
        </>
    )
}

export default MultipleAudioFileUpload;
