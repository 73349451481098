import AddIcon from "@mui/icons-material/Add";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { Alert, Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useNavigate } from 'react-router';
import ListDropdown from '../../components/ListDropdown';
import useAlbums from "../../hooks/useAlbums";
import useArtists from "../../hooks/useArtists";
import useGenres from "../../hooks/useGenres";
import useRecordings from "../../hooks/useRecordings";
import { IAlbum } from "../../models/IAlbum";
import { IArtist } from "../../models/IArtist";
import { IGenre } from "../../models/IGenre";
import { IRecording } from "../../models/IRecording";
import { useUser } from "../../store/context/UserContext";
import AvatarRowItem from "../AvatarRowItem";

let yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);


const AlbumsTable = () => {
    const { albums, fetchAlbums, isFetching } = useAlbums();
    const { state: { data: user } } = useUser();
    const { artists, fetchArtistById } = useArtists();
    const { recordings, fetchRecordings } = useRecordings();
    const { genres, fetchGenres } = useGenres();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            fetchAlbums();
            fetchRecordings();
            fetchGenres();
        }
    }, []);

    useEffect(() => {
        if (albums.length > 0) {
            for (const album of albums) {
                for (const artistId of album.artists) {
                    (async () => fetchArtistById(artistId))();
                }
            }
        }
    }, [albums])


    if (isFetching) return (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
        </Box>
    );

    if (!isFetching && albums.length <= 0) return (
        <Box>
            <Alert severity="warning" sx={{ alignItems: 'center', maxWidth: 'max-content', mb: 2 }}>
                You currently don't have any albums to show.
            </Alert>
            <Button variant="contained" onClick={() => navigate('/albums/create')}><AddIcon /> Create new album or single</Button>
        </Box>
    );

    const getRowData = (row: IAlbum): [IRecording[], IArtist[], IGenre[]] => {
        const _songs: IRecording[] = [];
        for (const songId of row.songs) {
            const recording = recordings.find(recording => recording.id === songId);
            if (recording) _songs.push(recording);
        }

        const _artists: IArtist[] = [];
        for (const artistId of row.artists) {
            const artist = artists.find(artist => artist.id === artistId);
            if (artist) _artists.push(artist);
        }

        const _genres: IGenre[] = [];
        for (const genreId of row.genres) {
            const genre = genres.find(genre => genre.id === genreId);
            if (genre) _genres.push(genre);
        }

        return [_songs, _artists, _genres];
    }

    const getRows = () => {
        return albums.map((album: IAlbum) => {
            const [songs, artists, genres] = getRowData(album);

            const isApproved = album.hasOwnProperty('approved') && !album.approved ? false : true;
            const isAnyRecordingUploading = songs.some((song) => (song.createdAt.toDate() > yesterday && !song.url)) ?? false;

            return {
                id: album.id,
                name: album.name,
                artists: artists,
                songs: songs,
                genres: genres.map(genre => genre.name).join(', '),
                recordingYear: album.recordingYear,
                createdAt: album.createdAt.toDate().toLocaleDateString(),
                approved: isApproved,
                edit: navigate,
                isUploading: isAnyRecordingUploading,
            }
        })
    }

    return (
        <DataGrid
            rows={getRows()}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableColumnMenu={true}
            disableColumnSelector={true}
            isRowSelectable={() => false}
            isCellEditable={() => false}
            disableSelectionOnClick={true}
            autoHeight={true}
        />
    )
}

const columns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        sortable: false,
        minWidth: 175,
        renderCell: (cell) => {
            if (cell.row.isUploading) {
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Tooltip title="Uploading recordings">
                            <CircularProgress size={20} sx={{ marginRight: 2 }} />
                        </Tooltip>
                        {cell.value}
                    </Box>
                )
            }
            return cell.value;
        }
    },
    {
        field: 'songs',
        headerName: 'Songs',
        renderCell: (cell) => {
            const songs: IRecording[] = cell.value;

            const songsMap = songs.map((song, i) => {
                const isUploading = (song.createdAt.toDate() > yesterday && !song.url) ?? false;
                return (
                    <Box key={i} sx={{ ...(isUploading && { opacity: 0.2 }) }}>
                        <AvatarRowItem name={song.name} img={song.img && `${song.img}?alt=media`} />
                    </Box>
                )
            });
            if (songs.length === 1) return songsMap;
            return (
                <ListDropdown label={`View ${songs.length} recordings`}>
                    <>{songsMap}</>
                </ListDropdown>
            )
        },
        flex: 1,
        minWidth: 185,
        sortable: false
    },
    {
        field: 'artists',
        headerName: 'Artists',
        renderCell: (cell) => {
            const artists: IArtist[] = cell.value;
            const isUploading = cell.row.isUploading;
            const artistsMap = artists.map((artist, i) => (
                <Box sx={{ ...(isUploading && { opacity: 0.2 }) }} key={i}>
                    <AvatarRowItem name={artist.name ?? ''} img={artist.img && `${artist.img}?alt=media`} />
                </Box>
            ));
            if (artists.length === 1) return artistsMap;
            return (
                <ListDropdown label={`View ${artists.length} artists`}>
                    <>{artistsMap}</>
                </ListDropdown>
            )
        },
        flex: 1,
        minWidth: 160,
        sortable: false
    },
    {
        field: 'genres',
        headerName: 'Genres',
        width: 220,
        flex: 1,
        minWidth: 100,
        sortable: false
    },
    {
        field: 'recordingYear',
        headerName: 'Recording year',
        width: 130,
        sortable: false
    },
    {
        field: 'createdAt',
        headerName: 'Created',
        width: 100,
        sortable: false
    },
    {
        field: 'approved',
        headerName: 'Approved',
        renderCell: ({ value: approved }) => {
            if (!approved) return <CancelIcon color="error" />
            return <CheckCircleIcon color="success" />
        },
        width: 100,
        sortable: false
    },
    {
        field: 'edit',
        headerName: 'Edit',
        renderCell: (cell) => {
            const id = cell.id;
            const navigate = cell.value;
            const isUploading = cell.row.isUploading;
            if (isUploading) return '';
            return (
                <IconButton onClick={() => navigate(`/albums/edit/${id}`)}>
                    <EditIcon sx={{ width: 20, height: 20 }} />
                </IconButton>
            )
        },
        width: 70,
        sortable: false,
    }
];

export default AlbumsTable;
