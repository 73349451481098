import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect } from 'react';

const getYears = () => {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let i = 1900; i <= currentYear; i++) years.push(i);
    return years;
}

interface YearAutocompleteFieldProps {
    value: number | null;
    label: string;
    error?: boolean;
    onChange: (event: React.ChangeEvent<{}>, value: number | null) => void;
}

const YearAutocompleteField = ({ value, label, error, onChange }: YearAutocompleteFieldProps) => {
    return (
        <Autocomplete
            value={value}
            onChange={onChange}
            options={getYears().reverse()}
            getOptionLabel={(option) => option.toString()}
            sx={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} error={error} label={label} />}
        />
    )
}

export default YearAutocompleteField;
