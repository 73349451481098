
import {getFirestore, collection, query, where, getDocs, getDoc, doc, setDoc, updateDoc, addDoc} from "firebase/firestore";

import firebase from "../firebase";

const useFirestore = () => {

    const collectionRef = (name: string) => {
        return collection(firebase.db, name);
    }

    const getAll = async (name: string) => {
        return await getDocs(collectionRef(name));
    }

    const getById = async(name: string, id: string) => {
        return await getDoc(doc(collectionRef(name), id));
    }

    const add = async (name: string, data: any) => {
        return await addDoc(collectionRef(name), data);
    }

    const update = async (name: string, id: string, data: any) => {
        return await updateDoc(doc(collectionRef(name), id), data);
    }

    return { getAll, collectionRef, getById, add, update };
}

export default useFirestore;
