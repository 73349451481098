import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import firebase from '../firebase';
import useAuth from '../hooks/useAuth';
import { UserActionType, useUser } from '../store/context/UserContext';
import { IUser } from '../models/IUser';
import { AppActionType, useApp } from '../store/context/AppContext';
import { StatisticsActionType, useStatisticsStore } from '../store/context/StatisticsContext';

const AppAuthWrapper = ({ children }: { children: JSX.Element }) => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { dispatch } = useUser();
    const { authenticate } = useAuth();
    const app = useApp();
    const statisticsContext = useStatisticsStore();
    const location = useLocation();

    useEffect(() => {
        const unsubscribe = firebase.auth.onAuthStateChanged(async (user) => {
            if (user) {
                const rootUser: IUser = await authenticate(user);
                // loadAllStatistics();

                dispatch({
                    type: UserActionType.SET_USER,
                    payload: rootUser
                });
                if(!rootUser.phoneVerified) {
                    navigate('/account');
                    setIsLoading(false);
                    return;
                } else if (!rootUser.termsAccepted) {
                    navigate('/terms');
                    setIsLoading(false);
                    return;
                } else if (location.pathname === '/login') {
                    navigate('/');
                    setIsLoading(false);
                    return;
                } else {
                    // We redirect user to whereever they came from
                    // Ex: accesses /wallet > not logged in > /login > user logs in > redirect to /wallet
                    navigate(location);
                }
            } else {
                dispatch({
                    type: UserActionType.RESET,
                });
                app.dispatch({
                    type: AppActionType.RESET
                });
                statisticsContext.dispatch({
                    type: StatisticsActionType.RESET
                });
                navigate('/login');
            }
            setIsLoading(false);
        });
        return unsubscribe;
    }, []);

    if (isLoading) return (
        <Box width="100%" height="100vh" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
        </Box>
    )

    return children;
}

export default AppAuthWrapper
