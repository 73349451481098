import { getAuth, User } from "firebase/auth";
import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import firebase from "../firebase";
import { UserActionType, useUser } from "../store/context/UserContext";
import axios from "axios";
import { IUser } from "../models/IUser";

const useAuth = () => {
    const { state, dispatch } = useUser();

    const isAuthenticated = () => {
        return state.data !== null;
    };

    const hasNoArtists = () => {
        return true;
        //return state.data.artists === undefined;
    };

    const signOut = async () => await getAuth().signOut();

    const authenticate = async (authUser: User): Promise<IUser> => {
        const {email, displayName, photoURL, uid} = authUser;
        const usersRef = collection(firebase.db, 'owners');

        const userSnap = await getDoc(doc(usersRef, uid));

        let user: IUser;
        if(userSnap.exists()) {
            // @ts-ignore
            user = {id: userSnap.id, ...userSnap.data()};
        } else {
            const userData = {
                isAdmin: false,
                email,
                name: displayName,
                image: photoURL,
                createdAt: new Date(),
            };

            await setDoc(doc(usersRef, uid), userData);

            user = {id: uid, ...userData};
        }

        return user;
    };

    return { isAuthenticated, signOut, authenticate, hasNoArtists };
}

export default useAuth;
