import { Grid, Paper, Typography } from "@mui/material"
import { Box } from "@mui/system"
import PayoutForm from '../../components/forms/PayoutForm'
import Navigation from "../../components/Navigation"
import WalletHistoryTable from '../../components/tables/WalletHistoryTable'
import { useApp } from "../../store/context/AppContext";

const Wallet = () => {
    const { state: { wallet } } = useApp();

    const walletAmount = wallet?.amount ?? 0;
    const eauAmount = wallet?.eauTotal ?? 0;
    const eelAmount = wallet?.eelTotal ?? 0;
    const paidAmount = wallet?.paid ?? 0;

    return (
        <Box display="flex" pb={12}>
            <Navigation />
            <Box p={{ xs: 2, md: 4 }} width="100%">
                <Typography variant="h3" fontWeight="bold" sx={{ mb: 4 }}>Wallet</Typography>
                <Grid container spacing={4}>

                    <Grid item xs={12} md={5}>
                        <Paper sx={{ p: 4 }}>
                            <Typography variant="h5">Balance</Typography>
                            <Box my={1}>
                                <Typography variant="h2" fontWeight="bold">{walletAmount?.toFixed(2)}€</Typography>
                                {!!wallet?.pending && <Typography variant="body1" color="#BABABA">Pending: {wallet.pending.toFixed(2)}€</Typography>}
                            </Box>
                            <Typography variant="h5">Payout</Typography>
                            <Typography variant="body1" color="#BABABA">min. 10€</Typography>
                            <Box my={2}>
                                <PayoutForm />
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper sx={{ p: 4 }}>
                            <Typography variant="h5">Payout history</Typography>
                            <Box my={1}>
                                <Typography variant="body1" color="#BABABA">
                                    User:
                                </Typography>
                                <Typography variant="body1" color="#000" fontWeight="bold">
                                    {paidAmount.toFixed(2)}€
                                </Typography>
                                <Typography variant="body1" color="#BABABA">
                                    Estonian Authors Union (EAÜ):
                                </Typography>
                                <Typography variant="body1" color="#000" fontWeight="bold">
                                    {eauAmount.toFixed(2)}€
                                </Typography>
                                <Typography variant="body1" color="#BABABA">
                                    Estonian Performers Union (EEL):
                                </Typography>
                                <Typography variant="body1" color="#000" fontWeight="bold">
                                    {eelAmount.toFixed(2)}€
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 4 }}>
                            <Typography variant="h5">Transaction history</Typography>
                            <Box my={1}>
                                <WalletHistoryTable />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Wallet
