import { Timestamp } from "@firebase/firestore";

export enum TransactionStatus {
    Pending = 'Pending',
    Completed = 'Completed',
    Failed = 'Failed',
}

export enum TransactionType {
    ROYALTIES_IN = 'ROYALTIES_IN',
    ROYALTIES_OUT = 'ROYALTIES_OUT',
}

export enum TargetType {
    OWNER = 'OWNER',
    EEL = 'EEL',
    EAU = 'EAU'
}

export interface ITransaction {
    id: string;
    type: TransactionType,
    periodFrom: string;
    periodTo: string;
    recipent: string;
    iban: string;
    amount: number;
    recipient: string;
    target: TargetType;
    status: TransactionStatus;
    createdAt: Timestamp;
}
