import { Alert, Card, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import TotalListenedChart from "../../components/charts/TotalListenedChart";
import Navigation from "../../components/Navigation";
import useAlbums from "../../hooks/useAlbums";
import useRecordings from "../../hooks/useRecordings";

const Home = () => {
    const { recordings, fetchRecordings } = useRecordings();
    const { albums, fetchAlbums } = useAlbums();

    useEffect(() => {
        fetchRecordings();
        fetchAlbums();
    }, []);

    return (
        <Box display="flex" pb={12}>
            <Navigation />
            <Box p={{ xs: 2, md: 4 }} width="100%">
                <Typography variant="h3" fontWeight="bold" sx={{ mb: 4 }}>Home</Typography>
                <Box my={4} maxWidth="max-content">
                    <Alert severity="info">Already have any albums or recordings on Fairmus? Please contact us at <a href="mailto:info@fairmus.com">info@fairmus.com</a> and we'll link them with your Artist Account.</Alert>
                </Box>
                <Grid container spacing={{ xs: 2, md: 4 }}>
                    <Grid item xs={6} md={4}>
                        <Card sx={{ p: { xs: 2, md: 4 } }}>
                            <Typography variant="h5">Recordings</Typography>
                            <Box my={1}>
                                <Typography variant="h2" fontWeight="bold">{recordings.length}</Typography>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Card sx={{ p: { xs: 2, md: 4 } }}>
                            <Typography variant="h5">Albums</Typography>
                            <Box my={1}>
                                <Typography variant="h2" fontWeight="bold">{albums.length}</Typography>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
                <Box my={4} />
                <Grid container spacing={{ xs: 2, md: 4 }}>
                    <Grid item xs={12} lg={8}>
                        <TotalListenedChart />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Home
