import { Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface ImageFileUploadType {
    preview?: string | null;
    label: string;
    error?: boolean;
    subtitle: string;
    onChange: (file: File | null) => void;
}

const ImageFileUpload = ({ preview, label, error, subtitle, onChange }: ImageFileUploadType) => {
    const [imageFile, setImageFile] = useState<File | null>(null);

    const [imagePreview, setImagePreview] = useState<string | null>(null);

    const onDrop = useCallback((acceptedFiles) => {
        const [image] = acceptedFiles;
        const previewImage = URL.createObjectURL(image);
        setImagePreview(previewImage);

        // Set file
        setImageFile(image);

    }, []);

    useEffect(() => {
        if (preview) {
            // Generate image preview
            setImagePreview(`${preview}?alt=media`);
        }
    }, [preview]);


    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpeg, image/png, image/jpg, image/webp' });

    useEffect(() => onChange(imageFile), [imageFile])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Typography variant="body2" fontWeight="bold">{label}</Typography>
                <Typography variant="body2">{subtitle}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <div {...getRootProps()} style={{
                    width: '100%',
                    maxWidth: '200px',
                    height: '200px',
                    backgroundColor: '#FAFAFA',
                    border: `${(imagePreview && !error) ? '0px' : '1px'} solid ${error ? 'red' : '#EAEAEA'}`,
                    borderRadius: (imagePreview && !error) ? 0 : 8,
                    padding: '16px',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                }}>
                    <input {...getInputProps()} multiple={false} />
                    {
                        isDragActive ?
                            <Typography textAlign="center">Drop the image here ...</Typography> :
                            <Typography textAlign="center">Drag 'n' drop an image here, or click to select image</Typography>
                    }
                    {imagePreview && <img src={imagePreview} alt="image preview" style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        backgroundColor: '#FAFAFA',
                    }} />}
                </div>
            </Grid>
        </Grid>
    )
}

export default ImageFileUpload
