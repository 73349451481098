
import { Button, ButtonGroup, Dialog, DialogContent, DialogTitle, Divider, Grid, Paper, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { createUserWithEmailAndPassword, linkWithCredential, FacebookAuthProvider, getAuth, GoogleAuthProvider, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, User, signInWithRedirect } from "firebase/auth";
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import facebookIconSrc from '../../assets/icons/facebook-icon.svg';
import googleIconSrc from '../../assets/icons/google-icon.svg';
import FullscreenOverlayLoader from '../../components/FullscreenOverlayLoader';
import useAuth from '../../hooks/useAuth';
import { IUser } from '../../models/IUser';
import { UserActionType, useUser } from '../../store/context/UserContext';
import firebase from "firebase/compat";
import fairmusLogoSrc from '../../assets/fairmus-logo.svg';

interface IFields {
    email?: string;
    password?: string;
}

const Login = () => {
    const [fields, setFields] = useState<IFields | null>(null);
    const [errors, setErrors] = useState<string[] | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentAuthSelection, setCurrentAuthSelection] = useState<number>(1);
    const { enqueueSnackbar } = useSnackbar();
    const { authenticate } = useAuth();
    const { dispatch } = useUser();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFields((p) => ({ ...p, [name]: value }));
    };

    const googleAuthProvider = new GoogleAuthProvider();
    googleAuthProvider.addScope('https://www.googleapis.com/auth/user.addresses.read');
    googleAuthProvider.addScope('https://www.googleapis.com/auth/userinfo.email');

    const facebookAuthProvider = new FacebookAuthProvider();
    facebookAuthProvider.addScope('email');
    facebookAuthProvider.addScope('public_profile');

    const signInWithGoogle = () => {
        signInWithPopup(getAuth(), googleAuthProvider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential?.accessToken;
                // The signed-in user info.
                const user = result.user;
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    };

    const signInWithFacebook = () => {
        signInWithPopup(getAuth(), facebookAuthProvider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = FacebookAuthProvider.credentialFromResult(result);
                const token = credential?.accessToken;

                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = FacebookAuthProvider.credentialFromError(error);

                if (error.code === 'auth/account-exists-with-different-credential') {


                    signInWithPopup(getAuth(), googleAuthProvider).then(function (result) {
                        // Remember that the user may have signed in with an account that has a different email
                        // address than the first one. This can happen as Firebase doesn't control the provider's
                        // sign in flow and the user is free to login using whichever account they own.
                        // Step 4b.
                        // Link to Facebook credential.
                        // As we have access to the pending credential, we can directly call the link method.
                        // @ts-ignore
                        linkWithCredential(result.user, credential).then(result => {
                            console.log("linked? ", result);
                        });
                        /*
                        result.user.linkAndRetrieveDataWithCredential(credential).then(function(usercred) {
                            // Facebook account successfully linked to the existing Firebase user.
                            // goToApp();
                        });

                         */
                    });

                }
            });
    };

    const handleUserCredentialLogin = async (user: User) => {
        const rootUser: IUser = await authenticate(user);
        dispatch({
            type: UserActionType.SET_USER,
            payload: rootUser
        });
    }

    const signInWithCredentials = async () => {
        if (!fields?.email || !fields?.password) return;
        if (typeof fields.email !== 'string' || typeof fields.password !== 'string') return;
        const auth = getAuth();

        setIsSubmitting(true);
        if (currentAuthSelection === 1) {
            // Log in user
            try {
                const userCredential = await signInWithEmailAndPassword(auth, fields.email, fields.password);

                await handleUserCredentialLogin(userCredential.user);
                setIsSubmitting(false);
            } catch (error: any) {
                setIsSubmitting(false);
                console.log('signInWithEmailAndPassword error', error)
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        } else if (currentAuthSelection === 2) {
            // Create user
            try {
                const userCredential = await createUserWithEmailAndPassword(auth, fields.email, fields.password);

                await handleUserCredentialLogin(userCredential.user);
                setIsSubmitting(false);

                enqueueSnackbar(`Artist Account successfully created`, { variant: 'success' });
            } catch (error: any) {
                setIsSubmitting(false);
                enqueueSnackbar(error.message, { variant: 'error' });
                console.log('createUserWithEmailAndPassword error', error)
            }
        }
    }

    const handlePasswordResetSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!fields || !fields?.email) return;

        const auth = getAuth();
        try {
            const result = await sendPasswordResetEmail(auth, fields.email);
            enqueueSnackbar(`Password reset link sent to ${fields.email}`, { variant: 'success' });
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const _errors = [];
        if (!fields?.email || !fields.email.match(/^\S+@\S+\.\S+$/)) _errors.push('email');
        if (!fields?.password || fields.password.length < 6) _errors.push('password');

        if (_errors.length > 0) {
            setErrors(_errors);
            return;
        } else setErrors(null);

        signInWithCredentials();
    }

    return (
        <>
            {isSubmitting && <FullscreenOverlayLoader />}
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <Paper style={{ margin: 8, position: 'relative' }}>
                    <Paper sx={{
                        borderRadius: 500,
                        position: 'absolute',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        top: -20,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 1,
                        backgroundColor: '#FFF',
                    }}>
                        <img src={fairmusLogoSrc} alt="" style={{ width: 24, height: 24, transform: 'translateX(2px)' }} />
                    </Paper>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center"
                        p={{ xs: 2, sm: 6 }}
                        py={{ xs: 6 }}
                    >
                        <Typography variant="h1" fontSize={[20, 28, 32]} fontWeight="bold" color="primary" textAlign="center">Fairmus Self-service</Typography>

                        <Box maxWidth="300px" mt={1}>
                            <Typography variant="body2" color="textSecondary" textAlign="center">
                                Publish your music for free on Fairmus.
                            </Typography>
                        </Box>
                        <Box height={40} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <IconButton
                                    onClick={signInWithGoogle}
                                    icon={googleIconSrc}
                                >
                                    Sign in with Google
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <IconButton
                                    onClick={signInWithFacebook}
                                    icon={facebookIconSrc}
                                >
                                    Sign in with Facebook
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider flexItem sx={{ my: 3 }}>or</Divider>
                        <ButtonGroup sx={{ mb: 3 }}>
                            <Button variant={currentAuthSelection === 1 ? 'contained' : 'outlined'} onClick={() => setCurrentAuthSelection(1)}>Log in</Button>
                            <Button variant={currentAuthSelection === 2 ? 'contained' : 'outlined'} onClick={() => setCurrentAuthSelection(2)}>Create account</Button>
                        </ButtonGroup>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                type="email"
                                name="email"
                                label="Email address"
                                size="small"
                                value={fields?.email ?? ''}
                                error={errors?.includes('email')}
                                onChange={handleInputChange}
                                sx={{ width: "100%" }}
                            />
                            <Box sx={{ height: 10 }} />
                            <TextField
                                type="password"
                                name="password"
                                label="Password"
                                size="small"
                                error={errors?.includes('password')}
                                value={fields?.password ?? ''}
                                onChange={handleInputChange}
                                sx={{ width: "100%" }}
                            />
                            {currentAuthSelection === 1 && (
                                <Button onClick={() => setModalOpen(p => !p)} sx={{ display: 'flex', ml: 'auto', mt: 1, fontSize: 12 }} color="info">
                                    Forgot password?
                                </Button>
                            )}
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                                <Button type="submit" variant="outlined">{currentAuthSelection === 1 ? 'Log in' : 'Create Account'}</Button>
                            </Box>
                        </form>
                    </Box>
                </Paper>
            </Box>
            <Dialog onClose={() => setModalOpen(p => !p)} open={modalOpen}>
                <DialogTitle>Password reset</DialogTitle>
                <DialogContent>
                    <form onSubmit={handlePasswordResetSubmit}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2, flexDirection: 'column' }}>
                            <TextField
                                type="email"
                                name="email"
                                label="Email address"
                                size="small"
                                value={fields?.email ?? ''}
                                onChange={handleInputChange}
                                sx={{ width: "100%" }}
                            />
                            <Button type="submit" sx={{ mt: 4 }} variant="contained">Get link</Button>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}

const IconButton = ({ onClick, icon, children }: { onClick: () => void, icon: string, children: string }) => {
    return (
        <Paper sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button onClick={onClick}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <img src={icon} alt="" style={{ width: 24, height: 24, marginRight: 10, marginLeft: 4 }} />
                </Box>
                <Box>
                    <Typography variant="body2" color="gray" fontWeight="500" sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}>{children}</Typography>
                </Box>
                <Box sx={{ width: 10 }} />
            </Button>
        </Paper>
    )
}

export default Login
