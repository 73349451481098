import { doc, getDoc } from "firebase/firestore";
import { AppActionType, useApp } from '../store/context/AppContext';
import useFirestore from './useFirestore';

const useArtists = () => {
    const { state, dispatch } = useApp();
    const { collectionRef } = useFirestore();

    const artists = state.artists ?? [];

    const fetchArtistById = async (id: string) => {
        // If we have an artist already in the store, skip fetching
        if (artists.length > 0) {
            const artist = artists.find((artist) => artist.id === id);
            if (artist) return artist;
        }

        const snapshot = await getDoc(doc(collectionRef('artists'), id));

        const data = {
            ...snapshot.data(),
            id: snapshot.id,
        }

        dispatch({
            type: AppActionType.ADD_ARTIST,
            payload: data
        });

        return data;
    }

    return { artists, fetchArtistById };
}

export default useArtists;
