import * as React from 'react'
import { IUser } from '../../models/IUser'

export enum UserActionType {
    SET_USER = 'SET_USER',
    RESET = 'RESET'
}
type Action = { type: UserActionType, payload?: any }
type Dispatch = (action: Action) => void
type UserProviderProps = { children: React.ReactNode }

interface IState {
    data: IUser | null;
}
const initialState: IState = {
    data: null
}

const UserStateContext = React.createContext<
    { state: IState; dispatch: Dispatch } | undefined
>(undefined);


function userReducer(state: IState, action: Action) {
    switch (action.type) {
        case UserActionType.SET_USER: {
            return {
                data: action.payload
            }
        }
        case UserActionType.RESET: {
            return initialState;
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function UserProvider({ children }: UserProviderProps) {
    const [state, dispatch] = React.useReducer(userReducer, initialState)
    const value = { state, dispatch }
    return (
        <UserStateContext.Provider value={value}>
            {children}
        </UserStateContext.Provider>
    )
}

function useUser() {
    const context = React.useContext(UserStateContext)
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider')
    }
    return context
}

export { UserProvider, useUser }
