import { Alert, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import AccountForm from "../../components/forms/AccountForm";
import Navigation from "../../components/Navigation";
import { useUser } from "../../store/context/UserContext";

const Account = () => {
    const { state, dispatch } = useUser();
    return (
        <Box display="flex" pb={12}>
            <Navigation />
            <Box p={{ xs: 2, md: 4 }} width="100%">
                <Typography variant="h3" fontWeight="bold" sx={{ mb: 4 }}>Account</Typography>
                <Grid container spacing={4}>
                    {!state?.data?.phoneVerified && (
                        <Grid item xs={12}>
                            <Alert severity="info">You need to verify your phone number first!</Alert>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ p: 4 }}>
                            <AccountForm />
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Account
