import { Avatar } from "@mui/material";
import { Box } from "@mui/system";
import MusicNoteIcon from '@mui/icons-material/MusicNote';

const AvatarRowItem = ({ name, img }: { name: string, img: string | undefined }) => {
    return (
        <Box display="flex" alignItems="center" my={0.5}>
            <Avatar src={img} alt={name} sx={{ width: 24, height: 24, bgcolor: img && '#EDEDED' }} variant="rounded">
                <MusicNoteIcon sx={{ width: 16, height: 16 }} />
            </Avatar>
            <Box sx={{ ml: 1 }}>{name}</Box>
        </Box>
    );
}
export default AvatarRowItem;
