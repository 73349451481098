import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import useGenres from '../../hooks/useGenres';
import { IGenre } from "../../models/IGenre";

interface GenreOptionType extends IGenre {
    inputValue: string;
}

interface GenreAutocompleteFieldProps {
    value?: GenreOptionType[] | null;
    error?: boolean;
    label: string;
    onValueChange: (value: IGenre[]) => void;
}

const GenreAutocompleteField = ({ value: controlledValue, error, label, onValueChange }: GenreAutocompleteFieldProps) => {
    const [value, setValue] = useState<GenreOptionType[]>([]);
    const [options, setOptions] = useState<GenreOptionType[]>([]);
    const { genres, fetchGenres } = useGenres();

    // If we have set values from parent, set it to local state
    useEffect(() => {
        if (controlledValue && controlledValue.length > 0 && value.length <= 0) {
            setValue(controlledValue);
        }
    }, [controlledValue]);

    useEffect(() => {
        if (options.length <= 0) fetchGenres();
    }, [options]);

    useEffect(() => {
        if (genres.length > 0) {
            const filteredGenres = genres.filter((g) => !g?.adminOnly);
            setOptions(filteredGenres.map((genre) => {
                return { ...genre, inputValue: genre.name }
            }));
        }
    }, [genres])

    const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: (string | GenreOptionType)[], reason: string) => {
        // If the user wants to clear the whole input field
        if (reason === 'clear') {
            setValue([]);
            return;
        }

        const [last] = Array.isArray(newValue) ? newValue.slice(-1) : [newValue];

        // If we are entering a new option by pressing the 'ENTER' key
        if (typeof last === 'string') {
            return;
        }

        // If we are removing a selected option
        if (reason === 'removeOption') {
            if (!newValue || typeof newValue === 'string' || !Array.isArray(newValue)) {
                setValue([]);
                return;
            }
            setValue(newValue as GenreOptionType[]);
            return;
        }

        // If the clicked option already exists in 'options' we have to add it to 'value'
        if (options.find((o) => o.id === last.id)) {
            setValue((p) => [...p, last]);
            return;
        }
    }

    useEffect(() => onValueChange(value as IGenre[]), [value]);

    return (
        <React.Fragment>
            <Autocomplete
                value={value}
                onChange={handleChange}
                options={options}
                getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.inputValue;
                }}
                multiple
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disableCloseOnSelect={true}
                renderOption={(props, option) => (
                    <li {...props}>
                        {option.inputValue}
                    </li>
                )}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} error={error} label={label} />}
            />
        </React.Fragment>
    );
}

export default GenreAutocompleteField;
