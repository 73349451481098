import * as React from 'react'
import { IStatistics } from '../../models/IStatistics'

export enum StatisticsActionType {
    SET_TOTAL_LISTENED = 'SET_TOTAL_LISTENED',
    SET_SONGS_LISTENED = 'SET_SONGS_LISTENED',
    SET_STATISTICS = 'SET_STATISTICS',
    ALL_STATS_LOADED = 'ALL_STATS_LOADED',
    RESET = 'RESET'
}
type Action = { type: StatisticsActionType, payload?: any }
type Dispatch = (action: Action) => void
type StatisticsProviderProps = { children: React.ReactNode }

type IState = IStatistics;

const initialState: IState = { allStatsLoaded: false};

const StatisticsStateContext = React.createContext<
    { state: IState; dispatch: Dispatch } | undefined
>(undefined);


function StatisticsReducer(state: IState, action: Action) {
    switch (action.type) {
        case StatisticsActionType.SET_TOTAL_LISTENED: {
            return {
                ...state,
                totalListened: action.payload
            }
        }
        case StatisticsActionType.SET_SONGS_LISTENED: {
            return {
                ...state,
                songsListened: action.payload
            }
        }
        case StatisticsActionType.SET_STATISTICS: {
            return {
                ...state,
                statistics: action.payload
            }
        }
        case StatisticsActionType.ALL_STATS_LOADED: {
            return {
                ...state,
                allStatsLoaded: action.payload
            }
        }
        case StatisticsActionType.RESET: {
            return initialState;
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function StatisticsProvider({ children }: StatisticsProviderProps) {
    const [state, dispatch] = React.useReducer(StatisticsReducer, initialState);
    const value = { state, dispatch }
    return (
        <StatisticsStateContext.Provider value={value}>
            {children}
        </StatisticsStateContext.Provider>
    )
}

function useStatisticsStore() {
    const context = React.useContext(StatisticsStateContext)
    if (context === undefined) {
        throw new Error('useStatistics must be used within a StatisticsProvider')
    }
    return context
}

export { StatisticsProvider, useStatisticsStore }
