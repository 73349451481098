import { Portal } from '@mui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useEffect, useRef, useState } from 'react';
import useWindowSize from '../hooks/useWindowSize';

interface ListDropdownProps {
    children: JSX.Element;
    label: string;
}

const ListDropdown = ({ label, children }: ListDropdownProps) => {
    const [open, setOpen] = useState(false);
    const container = useRef<HTMLHeadingElement>(null);
    const [rect, setRect] = useState<DOMRect | null>(null);
    const windowSize = useWindowSize();

    const handleClick = () => {
        setOpen((p) => !p);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (container.current && windowSize) {
            setRect(container.current.getBoundingClientRect())
        }
    }, [container, windowSize]);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box sx={{ position: 'relative' }} ref={container}>
                <Button onClick={handleClick}>
                    {label} <KeyboardArrowDownIcon style={{ transform: !open ? `rotate(-90deg)` : '', transition: '0.25s all ease' }} />
                </Button>
                {open && rect && (
                    <Portal>
                        <Paper sx={{
                            position: 'absolute',
                            zIndex: 1,
                            p: 1,
                            top: rect.top + rect.height,
                            left: rect.left,
                            width: rect.width,
                        }}>
                            {children}
                        </Paper>
                    </Portal>
                )}
            </Box>
        </ClickAwayListener>
    );
};

export default ListDropdown;
