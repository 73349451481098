import { useState } from 'react'
import { IRecording } from '../models/IRecording'
import { getDocs, query, where } from "firebase/firestore";
import useFirestore from './useFirestore';
import { useUser } from '../store/context/UserContext';
import { AppActionType, useApp } from '../store/context/AppContext';
import { isValidTimestamp } from '@firebase/util';
import * as firestore from 'firebase/firestore';

const useRecordings = () => {
    const [isFetching, setIsFetching] = useState(false);
    const { state, dispatch } = useApp();
    const { collectionRef, getById } = useFirestore();
    const { state: { data: user } } = useUser();

    const recordings = state.songs ?? [];

    const fetchRecordings = async ({ force = false }: { force?: boolean } = {}) => {
        // If we already have recordings, dont fetch again
        // We can bypass this check if we want to force a fetch
        if (!force && recordings.length > 0) return recordings;

        setIsFetching(true);
        const q = query(collectionRef('songs'), where('ownerId', '==', user?.id));
        const snapshot = await getDocs(q);

        const data: IRecording[] = snapshot.docs.map((doc) => {
            return {
                ...doc.data() as IRecording,
                id: doc.id,
            }
        });

        const sortedRecordings = data.sort((a, b) => {
            const aCreated = firestore.Timestamp.fromDate(a.createdAt.toDate()).toDate();
            const bCreated = firestore.Timestamp.fromDate(b.createdAt.toDate()).toDate();
            return bCreated.valueOf() - aCreated.valueOf();
        });

        setIsFetching(false);
        dispatch({
            type: AppActionType.SET_SONGS,
            payload: sortedRecordings
        })
        return data;
    };

    const fetchRecordingById = async (id: string) => {
        // First lets check if we have it in store
        const recording = recordings.find((recording) => recording.id === id);
        if (recording) return recording;

        // If not, lets fetch it
        try {
            const doc = await getById('songs', id);
            const data = doc.data() as IRecording;
            return {
                ...data,
                id: doc.id,
            };
        } catch (err) {
            console.error(`Fetching recordin by id failed`, err);
            return null;
        }
    }

    return { recordings, fetchRecordings, fetchRecordingById, isFetching };
}

export default useRecordings;
