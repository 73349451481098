import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router';
import Navigation from '../../components/Navigation';
import RecordingsTable from '../../components/tables/RecordingsTable';

const Recordings = () => {
    const navigate = useNavigate();

    return (
        <>
            <Box display="flex" pb={12}>
                <Navigation />
                <Box p={{ xs: 2, md: 4 }} width="100%">
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} lg={7}>
                            <Typography variant="h3" fontWeight="bold">Recordings</Typography>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 4 }} />
                    <RecordingsTable />
                </Box>
            </Box>
        </>
    )
}

export default Recordings
