import { Alert, Button, Checkbox, FormControlLabel, FormGroup, Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useNavigate } from "react-router";
import Navigation from "../../components/Navigation";
import TermsContent from "../../components/TermsContent";
import useFirestore from "../../hooks/useFirestore";
import { UserActionType, useUser } from "../../store/context/UserContext";

const Terms = () => {
    const [checked, setChecked] = useState(false);

    const { state, dispatch } = useUser();
    const { update } = useFirestore();
    const navigate = useNavigate();


    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleAcceptTerms = async () => {
        if (!checked) return;
        if (!state?.data?.id) return;

        await update('owners', state.data.id, {
            termsAccepted: true,
            termsAcceptedDate: new Date()
        });

        const updatedUser = {
            ...state?.data,
            termsAccepted: true
        };

        dispatch({
            type: UserActionType.SET_USER,
            payload: updatedUser
        });

        navigate('/');
    };

    return (
        <Box display="flex" pb={12}>
            <Navigation />
            <Box p={{ xs: 2, md: 4 }} width="100%" maxWidth="1140px" mx="auto">
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={8}>
                        {!state.data?.termsAccepted ? (
                            <Alert severity="info" sx={{ mb: 4 }}>Before getting access, you need to accept Terms & Conditions of Use</Alert>
                        ) : (
                            <Alert severity="success" sx={{ mb: 4 }}>You have accepted the Terms & Conditions of Use</Alert>
                        )}
                        <Paper sx={{ p: 4 }}>
                            <TermsContent />
                        </Paper>
                        {!state?.data?.termsAccepted && (
                            <Grid item sx={{ my: 8, display: 'flex', flexDirection: 'column' }}>
                                <FormGroup sx={{ mx: 'auto' }}>
                                    <FormControlLabel
                                        control={<Checkbox checked={checked} onChange={(e) => handleCheck(e)} />}
                                        label="I have read and accept terms and conditions" />
                                </FormGroup>
                                <Button variant={checked ? 'contained' : 'outlined'} onClick={() => handleAcceptTerms()} sx={{ mx: 'auto' }}>Accept</Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Terms
