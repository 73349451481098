import { getFunctions, httpsCallable } from "@firebase/functions";
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import { CircularProgress, Grid, Paper, Typography } from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useUser } from "../../../store/context/UserContext";
import useStatistics from "../../../hooks/useStatistics";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import etLocale from 'date-fns/locale/et';


const gd = (b: number, d?: Date) => {
    const c = d ? new Date(d) : new Date();
    c.setDate(c.getDate() - b);
    return c;
}

const dbd = (a: Date, b: Date) => {
    const aD = new Date(a);
    const bD = new Date(b);
    const msPerDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs((aD.getTime() - bD.getTime()) / msPerDay));
}

interface IDayData {
    date: string;
    seconds: number;
}

interface ISongData {
    songId: string;
    seconds: number;
}

interface ISettings {
    start: Date;
    end: Date;
}

type QueryData = {
    [key: string]: number
}

const defaultSettings: ISettings = {
    start: gd(6),
    end: new Date()
}

const TotalListenedChart = () => {
    const [value, setValue] = useState<DateRange<Date>>([defaultSettings.start, defaultSettings.end])
    const [defaultSet, setDefaultSet] = useState(false)
    const { state: { data: user } } = useUser();

    const { totalListened, songsListened, loadStatistics, isFetching } = useStatistics();

    const fetchData = async () => {
        if (value.length === 2 && value[0] && value[1]) {
            loadStatistics(new Date(value[0]), new Date(value[1]));
        }
    }

    useEffect(() => {
        if (user && defaultSet) fetchData();
    }, [user, value, defaultSet]);

    /* Save settings to local storage, get settings from local storage */

    const saveSettingsToLocalStorage = (params: ISettings) => {
        const { start, end } = params;
        localStorage.setItem('totalListenedChartSettings', JSON.stringify({ start, end }));
    }

    const getSettingsFromLocalStorage = (): ISettings => {
        const settings = localStorage.getItem('totalListenedChartSettings');
        if (settings) return JSON.parse(settings);
        return defaultSettings;
    }

    const getDuration = (seconds: number) => {
        const hours = parseInt((seconds / 60 / 60).toString());
        let minutes = parseInt((seconds / 60).toString());
        if(hours > 0) {
            minutes = minutes - (hours * 60);
        }

        const remainingSeconds = Math.round(seconds % 60);
        return hours + "h " + minutes + "min " + remainingSeconds + "sec";
    }

    useEffect(() => {
        const { start, end } = getSettingsFromLocalStorage();
        setValue([start, end]);
        setDefaultSet(true);
    }, []);

    useEffect(() => {
        if (value && value[0] && value[1]) saveSettingsToLocalStorage({ start: value[0], end: value[1] });
    }, [value])

    if (isFetching) return (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
        </Box>
    );

    return (
        <Paper sx={{ p: {xs: 2, md: 4} }}>
            <Grid container spacing={2} mb={4}>
                <Grid item xs={12} md={7} lg={6}>
                    <Typography variant="h5" fontWeight="bold">Total listened</Typography>
                </Grid>
                <Grid item xs={12} md={5} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={etLocale}>
                        <DateRangePicker
                            startText="Start"
                            endText="End"
                            value={value}
                            onChange={(newValue) => setValue(newValue)}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <TextField {...startProps} size="small" />
                                    <Typography sx={{ mx: 2 }}> to </Typography>
                                    <TextField {...endProps} size="small" />
                                </React.Fragment>
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <ResponsiveContainer width="100%" height={400} >
                <LineChart
                    width={500}
                    height={300}
                    data={totalListened}
                >
                    <CartesianGrid strokeDasharray="3 3" strokeOpacity="0.4" />
                    <XAxis dataKey="date" />
                    <YAxis yAxisId="left"/>
                    <YAxis yAxisId="right" orientation="right" allowDecimals={false}/>
                    <Tooltip />
                    <Legend />
                    <Line yAxisId="left" type="monotone" dataKey="seconds" stroke="#443DF6" activeDot={{ r: 8 }} />
                    <Line yAxisId="right" type="monotone" dataKey="listenings" stroke="#82ca9d" />
                </LineChart>
            </ResponsiveContainer>
            {songsListened.length > 0 && (
                <Box sx={{ my: 2 }}>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>In playlists</TableCell>
                                    <TableCell>Likes</TableCell>
                                    <TableCell>Listenings</TableCell>
                                    <TableCell>Seconds</TableCell>
                                    <TableCell>Duration</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {songsListened.map((row, i) => (
                                    <TableRow
                                        key={i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{row.songName}</TableCell>
                                        <TableCell>{row.inPlaylistsCount}</TableCell>
                                        <TableCell>{row.likedCount}</TableCell>
                                        <TableCell>{row.listenings}</TableCell>
                                        <TableCell>{row.seconds}</TableCell>
                                        <TableCell>{getDuration(row.seconds)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </Paper>
    );
};

export default TotalListenedChart;
