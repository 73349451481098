import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { RejectedFilesErrorResponse } from './MultipleAudioFileUpload';

const AudioFileUpload = ({ preview, error, maxSize, onChange }: { preview?: string | null, error: boolean | null, maxSize?: number, onChange: (file: File | null) => void }) => {
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [audioPreview, setAudioPreview] = useState<string | null>(null);
    const [rejectedFiles, setRejectedFiles] = useState<RejectedFilesErrorResponse[] | null>(null);

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) setRejectedFiles(rejectedFiles);
        else setRejectedFiles(null);
        if (rejectedFiles?.length <= 0) {
            const [file] = acceptedFiles;
            setUploadedFile(file);
            setAudioPreview(URL.createObjectURL(file));
        }
    }, []);

    useEffect(() => {
        if (preview) {
            // Generate audio playback preview
            setAudioPreview(`${preview}?alt=media`);
        }
    }, [preview]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'audio/*', maxSize: maxSize ?? Infinity });

    useEffect(() => onChange(uploadedFile), [uploadedFile])

    return (
        <>
            <div {...getRootProps()} style={{
                width: '100%',
                height: '200px',
                backgroundColor: '#FAFAFA',
                border: `1px solid ${error ? 'red' : '#EAEAEA'}`,
                borderRadius: 8,
                padding: '16px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                flexDirection: 'column'
            }}>
                <input {...getInputProps()} multiple={false} />
                {
                    isDragActive ?
                        <Typography textAlign="center">Drop the audio file here ...</Typography>
                        : uploadedFile ?
                            <Typography textAlign="center">{uploadedFile.name}</Typography>
                            : <Typography textAlign="center">Drag 'n' drop any audio file here, or click to select an audio file</Typography>
                }
                {audioPreview && <audio controls src={audioPreview} />}
            </div>
            {rejectedFiles && rejectedFiles.length > 0 && (
                <Box>
                    <Divider sx={{ my: 4 }} />
                    <Typography variant="body1" color="error">The following {rejectedFiles.length === 1 ? 'file exceeds' : 'files exceed'} the 1GB size limit:</Typography>
                    {rejectedFiles.map((response) => {
                        return <Typography variant="body2" key={response.file.name}>{response.file.name}</Typography>
                    })}
                </Box>
            )}
        </>
    )
}

export default AudioFileUpload
