import {Button, Checkbox, FormControlLabel, Grid, Step, StepLabel, Stepper, TextField, Typography} from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ArtistAutocompleteField from '../../components/fields/ArtistAutocompleteField';
import AudioFileUpload from '../../components/fields/AudioFileUpload';
import YearAutocompleteField from '../../components/fields/YearAutocompleteField';
import FullscreenOverlayLoader from '../../components/FullscreenOverlayLoader';
import Navigation from '../../components/Navigation';
import useFirestore from '../../hooks/useFirestore';
import useRecordings from '../../hooks/useRecordings';
import useArtists from '../../hooks/useArtists';
import { IArtist } from '../../models/IArtist';
import { IGenre } from "../../models/IGenre";
import { useUser } from '../../store/context/UserContext';
import { getSongMediaUrl, uploadSongFile } from '../../utils';
import { Timestamp } from '@firebase/firestore';
import MoodAutocompleteField from '../../components/fields/MoodAutocompleteField';
import { RecordingMood } from '../../models/IRecording';
import { config } from '../../config';

export interface IRecordingFields {
    id?: string;
    name?: string;
    artists?: IArtist[];
    isrc?: string;
    img?: string;
    url?: string;
    recordingYear?: number | null;
    audioFile?: File | null;
    createdAt?: Timestamp | null;
    availableFrom?: Timestamp | null;
    approved?: boolean;
    explicit?: boolean;
    moods?: RecordingMood[] | null;
}

const steps = ['Recording', 'Audio file'];

const RecordingsCreator = () => {
    const [fields, setFields] = useState<IRecordingFields>({});
    const [currentStep, setCurrentStep] = useState(0);
    const [errors, setErrors] = useState<string[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { state: { data: user } } = useUser();
    const { add, update } = useFirestore();
    const navigate = useNavigate();
    const { fetchRecordingById, fetchRecordings } = useRecordings();
    const { fetchArtistById } = useArtists();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const isEditing = !!id;

    useEffect(() => {
        if (isEditing) {
            (async () => {
                const recording = await fetchRecordingById(id);
                if (!recording) {
                    enqueueSnackbar('Recording not found', { variant: 'error' });
                    navigate('/recordings');
                    return;
                }

                // Lets get all the recording artists
                const artists = await Promise.all(recording.artists.map(async (artistId) => {
                    const artist = await fetchArtistById(artistId);
                    return artist;
                }));

                setFields((p) => ({
                    ...p,
                    ...recording,
                    artists,
                }))
            })();
        }
    }, [isEditing]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let errors = [];
        if (!fields?.name) errors.push('name');
        if (!fields?.artists || fields?.artists?.length <= 0) errors.push('artists');
        if (!fields?.audioFile && !fields?.url) errors.push('audioFile');
        if (!user?.recordLabel) {
          //  if (!fields?.moods || fields?.moods?.length <= 0) errors.push('moods');
        }

        if (errors.length > 0) {
            console.log(errors);
            setErrors(errors);

            enqueueSnackbar('Please fill out all required fields', { variant: 'error' });
            return;
        } else setErrors([]);

        const songData = {
            name: fields.name,
            artists: fields.artists?.map((artist) => artist.id) ?? [],
            createdAt: fields?.createdAt ?? new Date(),
            availableFrom: fields?.availableFrom ?? new Date(),
            img: fields?.img ?? null,
            url: fields?.url ?? null,
            isrc: fields?.isrc ?? null,
            explicit:fields?.explicit ?? false,
            recordingYear: fields?.recordingYear ?? null,
            ownerId: user?.id,
            approved: fields?.approved ?? false,
            moods: fields?.moods ?? [],
        };

        setIsSubmitting(true);

        if (isEditing) {
            try {
                // If we have changed the audio file, update it
                let newUrl = songData.url ?? null;
                if (fields.audioFile) {
                    await uploadSongFile(id, fields.audioFile as File);
                    const mediaUrl = getSongMediaUrl(id, fields.audioFile as File);
                    newUrl = mediaUrl
                }
                await update('songs', id, { ...songData, url: newUrl });

                // Fetch all recordings again
                await fetchRecordings({ force: true });

                setIsSubmitting(false);
                enqueueSnackbar('Recording updated', { variant: 'success' });
                navigate('/recordings');
            } catch (err) {
                setIsSubmitting(false);
                enqueueSnackbar('Error updating recording, try again later', { variant: 'error' });
                return;
            }
            return;
        }

        try {
            const song = await add('songs', songData);
            await uploadSongFile(song.id, fields.audioFile as File);
            const mediaUrl = getSongMediaUrl(song.id, fields.audioFile as File);
            await update('songs', song.id, { url: mediaUrl });

            // Fetch all recordings again
            await fetchRecordings({ force: true });

            setIsSubmitting(false);
            enqueueSnackbar('Recording created', { variant: 'success' });
            navigate('/recordings');
        } catch (err) {
            console.log(`Adding song failed: `, err);
            enqueueSnackbar('Creating a recording failed, try again later', { variant: 'error' });
            setIsSubmitting(false);
            return;
        }
    }

    const isFirstStep = currentStep === 0;
    const isFinalStep = currentStep === steps.length - 1;
    return (
        <Box display="flex" pb={12}>
            <Navigation />
            <Box p={{ xs: 2, md: 4 }} maxWidth="1200px" width="100%">
                <Typography variant="h3" fontWeight="bold">{isEditing ? 'Edit' : 'Add'} Recording</Typography>
                <Box mt={8} />
                <Stepper activeStep={currentStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box mt={8} maxWidth="500px" mx="auto">
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ display: currentStep === 0 ? 'block' : 'none' }}>
                            <RecordingInfoForm
                                fields={fields}
                                errors={errors}
                                setFields={(name, value) => setFields((p) => ({ ...p, [name]: value }))}
                            />
                        </Box>
                        <Box sx={{ display: currentStep === 1 ? 'block' : 'none' }}>
                            <AudioFileUpload
                                maxSize={config.maxFileSize}
                                error={errors?.includes('audioFile')}
                                preview={fields?.url ?? null}
                                onChange={(file) => setFields((p) => ({ ...p, audioFile: file }))}
                            />
                        </Box>
                        <Box sx={{ my: 4 }} />
                        <Box width="100%" display="flex" justifyContent="center">
                            {!isFirstStep && (
                                <Button onClick={() => {
                                    if (currentStep <= 0) return;
                                    setCurrentStep((p) => p - 1);
                                }}>Back</Button>
                            )}
                            <Button
                                variant="contained"
                                type={isFinalStep ? 'submit' : 'button'}
                                onClick={() => {
                                    if (!isFinalStep) setTimeout(() => setCurrentStep((p) => p + 1));
                                }}
                            >
                                {isFinalStep ? 'Submit' : 'Next'}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Box>
            {isSubmitting && <FullscreenOverlayLoader />}
        </Box>
    )
}

interface IRecordingInfoFormProps {
    fields: IRecordingFields;
    errors: string[];
    setFields: (name: string, value: any) => void;
}

export const RecordingInfoForm = ({ fields, errors, setFields }: IRecordingInfoFormProps) => {

    const isError = (name: string) => errors?.includes(name) ?? false;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    name="name"
                    label="Recording name"
                    value={fields?.name ?? ''}
                    error={isError('name')}
                    onChange={(event) => setFields('name', event.target.value)}
                    sx={{ width: "100%" }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="isrc"
                    label="ISRC"
                    value={fields?.isrc ?? ''}
                    error={isError('isrc')}
                    onChange={(event) => setFields('isrc', event.target.value)}
                    sx={{ width: "100%" }}
                    helperText={<>
                        ISRC is optional, but required on payout. <a target="_blank" href="https://repsearch.ppluk.com/">Find my ISRC code</a>
                    </>}
                />
            </Grid>
            <Grid item xs={12}>
                <YearAutocompleteField
                    value={fields.recordingYear ?? null}
                    label="Recording year"
                    error={isError('recordingYear')}
                    onChange={(event, value) => setFields('recordingYear', value)}
                />
            </Grid>
            <Grid item xs={12}>
                <ArtistAutocompleteField
                    value={fields.artists?.map((artist) => {
                        return { ...artist, inputValue: artist.name ?? '' }
                    }) ?? []}
                    error={isError('artists')}
                    onValueChange={(artists) => setFields('artists', artists)}
                    label="Artists"
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox
                        onChange={(_, value) => setFields('explicit', value)}
                        checked={fields?.explicit ?? false}
                        size="small"
                    />}
                    label="This song contains explicit lyrics."
                />
            </Grid>
            <Grid item xs={12}>
                <MoodAutocompleteField
                    value={fields.moods ?? null}
                    label="Moods"
                    error={isError('moods')}
                    onChange={(event, value) => setFields('moods', value)}
                />
            </Grid>
        </Grid>
    )
}

export default RecordingsCreator;
