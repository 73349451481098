import * as React from 'react'
import { IApp } from '../../models/IApp'
import { IRecording } from '../../models/IRecording'

export enum AppActionType {
    SET_SONGS = 'SET_SONGS',
    UPDATE_SONG = 'UPDATE_SONG',
    SET_ALBUMS = 'SET_ALBUMS',
    SET_ARTISTS = 'SET_ARTISTS',
    ADD_ARTIST = 'ADD_ARTIST',
    SET_GENRES = 'SET_GENRES',
    SET_TRANSACTIONS = 'SET_TRANSACTIONS',
    SET_WALLET_AMOUNT = 'SET_WALLET_AMOUNT',
    RESET = 'RESET'
}
type Action = { type: AppActionType, payload?: any }
type Dispatch = (action: Action) => void
type AppProviderProps = { children: React.ReactNode }

type IState = IApp;

const initialState: IState = {};

const AppStateContext = React.createContext<
    { state: IState; dispatch: Dispatch } | undefined
>(undefined);


function appReducer(state: IState, action: Action) {
    switch (action.type) {
        case AppActionType.SET_SONGS: {
            return {...state, songs: action.payload }
        }
        case AppActionType.UPDATE_SONG: {
            const recording: IRecording = action.payload;
            if (!recording || !recording.id) return state;

            const newSongs = state.songs?.map((song: IRecording) => {
                if (song.id === recording.id) return recording;
                return song;
            });

            return {
                ...state,
                songs: newSongs
            }
        }
        case AppActionType.SET_ALBUMS: {
            return {...state, albums: action.payload }
        }
        case AppActionType.SET_ARTISTS: {
            return {...state, artists: action.payload }
        }
        case AppActionType.ADD_ARTIST: {
            const value = state.artists && state.artists?.length > 0 ? [...state.artists, action.payload] : [action.payload];
            return {...state, artists: value }
        }
        case AppActionType.SET_GENRES: {
            return {...state, genres: action.payload }
        }
        case AppActionType.SET_TRANSACTIONS: {
            return {...state, transactions: action.payload }
        }
        case AppActionType.SET_WALLET_AMOUNT: {
            return {
                ...state,
                wallet: {
                    ...state.wallet,
                    ...action.payload,
                }
            }
        }
        case AppActionType.RESET: {
            return initialState;
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function AppProvider({ children }: AppProviderProps) {
    const [state, dispatch] = React.useReducer(appReducer, initialState);
    const value = { state, dispatch }
    return (
        <AppStateContext.Provider value={value}>
            {children}
        </AppStateContext.Provider>
    )
}

function useApp() {
    const context = React.useContext(AppStateContext)
    if (context === undefined) {
        throw new Error('useApp must be used within a AppProvider')
    }
    return context
}

export { AppProvider, useApp }
