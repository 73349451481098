import { Typography } from '@mui/material';
import React from 'react';

const TermsContent = () => {
    return (
        <>
            <Typography variant="subtitle1" fontWeight="bold" align="center">FAIRMUS FOR ARTISTS</Typography>
            <Typography variant="subtitle1" fontWeight="bold" align="center">TERMS AND CONDITIONS OF USE</Typography>

            <Typography variant="subtitle1" fontWeight="bold" pt={1}>General information</Typography>

            <Typography variant="subtitle1" pt={1}>
                These terms and conditions of use (hereinafter <strong>“T&Cs”</strong>) are entered into between you as the artist (hereinafter <strong>“Artist”</strong> or <strong>“you”</strong>, whether a natural person or an entity, organisation or business) and Fairmus Technologies OÜ (a company incorporated under Estonian law, whose registered office is located at Tatari tn 31-5, Tallinn 10118, Estonia, registry code 16115366) as the service provider (hereinafter <strong>“Fairmus”</strong>, <strong>“us”</strong> or <strong>“we”</strong>),  and govern your use of the Fairmus music streaming platform accessible via <a href="https://fairmus.com/" target="_blank" rel="noreferrer noopener">www.fairmus.com</a> or any subdomains thereto and via mobile application Fairmus (hereinafter <strong>“Fairmus Platform”</strong>).
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                The purpose of these T&Cs is to define the terms and conditions according to which Fairmus enables you to use certain services with features and functionalities of uploading and making available your content (in particular, your music) through the Fairmus Platform (hereinafter the <strong>“Services”</strong>).
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                To be able to access the Services, you are required to register for an artist account (hereinafter <strong>“Artist Account”</strong>) by providing certain registration data, including your name and e-mail address, and selecting a password. By registering an Artist Account, you accept and agree to these T&Cs which will, from that moment on, become binding to you. You are responsible for updating your Artist Account information as necessary.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                Your Artist Account stands separate from any other Fairmus Platform user accounts you may have and to which separate terms and conditions may apply.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                By registering for Artist Account to access the Services through the Fairmus Platform, you confirm that: (i) you have the legal capacity to use the Services; (ii) you have the power to enter into a binding contract with us and are in no way prohibited from doing so under any applicable laws, (iii) you are the rightful holder of and have the full authority to grant us any rights you are required to grant us under these T&Cs, and (iv) you will use your Artist Account in accordance with these T&Cs and applicable legal and/or regulatory provisions.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                Fairmus reserves the right to unilaterally modify and update the T&Cs at any time, provided that the modifications and updates are required based on justified reasons (e.g. change in applicable laws, reasonable business interests of Fairmus, improving the existing features, etc.), it being specified that any change relating to the said T&Cs will be published on this page. We will notify you of any material modifications and updates in the T&Cs by sending respective notice to you via your Artist Account on the Fairmus Platform or e-mail which you have provided for us.
            </Typography>

            <Typography variant="subtitle1" fontWeight="bold" pt={1}>Using Fairmus Platform</Typography>

            <Typography variant="subtitle1" pt={1}>
                All rights to Fairmus Platform belong to Fairmus. We hereby grant you a limited, non-exclusive and revocable permission to access the Fairmus Platform and use the Services for as long as your Artist Account remains valid. You may access the Fairmus Platform by logging into your Artist Account on the Fairmus Platform with your personal credentials, i.e. e-mail address and password. You will then have the possibility to use the Services of your choice listed on the Fairmus Platform.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                You may delegate your rights to access Fairmus Platform and use the Services to your authorised representatives (hereinafter <strong>“Representatives”</strong>). Your representatives will be able to sign in to your Artist Account on the Fairmus Platform by using their personal credentials. You acknowledge and agree that these T&Cs govern the use of your Artist Account both by you and your Representatives. You represent, warrant and covenant to us that each of your Representatives who has been provided access to your Artist Account is authorised to access the Fairmus Platform and use the Services on your behalf. If you delegate such authority to any one or more Representatives, you expressly agree and ensure that: (i) all of your Representatives will use your Artist Account in accordance with these T&Cs and applicable legal and/or regulatory provisions and (ii) you remain fully liable for your and each Representative’s use of the Artist Account and any violation of these T&Cs.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                Other than the delegation of rights to your authorised representatives, you are not entitled to transfer or license your right to access the Fairmus Platform and use the Services, or transfer or license your Artist Account, to any third parties.
            </Typography>

            <Typography variant="subtitle1" fontWeight="bold" pt={1}>Management of Fairmus Platform</Typography>

            <Typography variant="subtitle1" pt={1}>
                Fairmus Platform is accessible online, 7 days a week, 24 hours a day, except in cases of a Force Majeure Event, scheduled or unscheduled interruptions that may result from a need for maintenance. For the purposes of these T&Cs a “Force Majeure Event” is defined as the occurrence of any unforeseen circumstance without any fault on party’s part or beyond party’s reasonable control, including, without limitation, Internet outages, communications outages, issues and problems relating to information technology, fire, flood or war.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                Fairmus reserves the right at any time to suspend, interrupt or limit access to all or part of the Fairmus Platform for any reason whatsoever and in particular for maintenance or updating purposes or in the event of an attack on the Fairmus Platform.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                The existence of a hypertext link to a third party webpage on the Fairmus Platform referring to another site does not imply a validation of legality of this third party webpage or any of its content by Fairmus. Fairmus Platform may be also integrated with third party applications and/or devices. In this respect, the Fairmus Platform and the third party webpages, applications and devices are strictly independent and you are responsible for complying with all relevant terms and conditions associated with any third party webpages and applications. You agree that Fairmus is not liable for the content, features or functionalities of any third party webpages and applications or devices.
            </Typography>

            <Typography variant="subtitle1" fontWeight="bold" pt={1}>Ownership of the properties</Typography>

            <Typography variant="subtitle1" pt={1}>
                All Fairmus trademarks, logos and any other distinctive signs and any software used or included on the Fairmus Platform, as well as third party content (including, but not limited to, any texts, audio or video clips, editorial content, photographs or any other information in any format and of any nature whatsoever appearing on the Fairmus Platform) (hereinafter <strong>"Properties"</strong>) are the exclusive property of Fairmus, Fairmus’ licensors or third parties having authorised them to use said content.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                The Properties may be protected by copyright, trademark, patent or any other intellectual property right. Information (including the Properties) published on the Fairmus Platform may under no circumstances be used for commercial or advertising purposes, or for any other purposes not expressly permitted under these T&Cs.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                For avoidance of any doubt, Fairmus prohibits the extraction, reuse, transferring, recording, broadcasting, marketing, distribution, modification, adaptation, translation, as well as reproduction of all or part of the elements (including the Properties) of the Fairmus Platform without its prior and express written authorisation. Failure to comply with this prohibition constitutes an infringement that may result in civil and criminal liability.
            </Typography>

            <Typography variant="subtitle1" fontWeight="bold" pt={1}>Artist content</Typography>

            <Typography variant="subtitle1" pt={1}>
                You may upload and make available, or allow your Representatives or Fairmus to upload and make available, to Fairmus users your content (in particular, your music in audio and video files, but also pictures, texts, messages, etc.) through the Fairmus Platform (hereinafter <strong>“Artist Content”</strong>). Fairmus has the right to demand validation of your Artist Account, your Representatives and the identity of any natural persons linked thereto before allowing you or your Representatives to upload any Artist Content. For this purpose, Fairmus has the right to contact you or your Representatives via the contact details you or your Representatives have provided to Fairmus.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                All rights to any uploaded Artist Content will belong to you. However, by uploading and making your Artist Content available through Fairmus Platform or allowing the same to be done by your Representatives or Fairmus, you grant Fairmus a non-exclusive, transferable, sub-licensable, worldwide license to use, reproduce, make available to users and the general public, publish and distribute your Artist Content on the Fairmus Platform and in connection with Fairmus’ services and business, in any and all formats through any and all media now known or developed in future. To the extent you upload and make available (or allow to be uploaded and made available by your Representatives or Fairmus) any Artist Content that contains your name, likeness or photograph, you further grant Fairmus a non-exclusive, transferable, sub-licensable, worldwide right to use such name, likeness, and photograph on the Fairmus Platform and in connection with Fairmus’ services and business.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                You represent and warrant that with respect to any Artist Content you upload and make available through Fairmus Platform or allow to be uploaded and made available by your Representatives or Fairmus: (i) you have the full right to upload and make available such Artist Content, and to grant Fairmus all rights to such Artist Content as set forth in these T&Cs, (ii) such Artist Content, and its use by Fairmus, does not violate any third party rights; (iii) Fairmus has no obligation to pay any fees or royalties with regard to such Artist Content to any third parties, including to any collective management organisations, unless explicitly agreed otherwise.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                You and your Representatives are prohibited to upload and make available through Fairmus Platform any Artist Content that: (i) is illegal or promotes an illegal act of any kind; (ii) promotes or incites violence, (iii) is offensive, discriminative, or defamatory; (iv) violates any third party rights; or (v) includes possible viruses, trojan horses or could otherwise compromise the security of the Fairmus Platform.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                You acknowledge and agree that Fairmus may, but has no obligation to, review your Artist Content and decide on whether it can or cannot be uploaded and made available through Fairmus Platform. Fairmus reserves the right, at any time, to remove your Artist Content from the Fairmus Platform or disable access to your Artist Content by users of the Fairmus Platform, for any or no reason, including when Fairmus considers in its sole discretion your Artist Content to violate any third party rights. Fairmus is entitled to take such actions without prior notice to you and without Fairmus having any liability in front of you for such removal or disabling of access.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                You acknowledge and agree that Fairmus has no responsibility with regard to your Artist Content. Should anyone bring a claim against Fairmus in relation to your Artist Content, you will indemnify and hold Fairmus harmless from and against all damages, losses, and expenses of any kind (including reasonable attorney fees and costs) arising out of or in relation to such claim.
            </Typography>

            <Typography variant="subtitle1" fontWeight="bold" pt={1}>Royalty distribution</Typography>

            <Typography variant="subtitle1" pt={1}>
                You acknowledge and agree to Fairmus’ royalty distribution system according to which Fairmus divides subscription fees collected from the Fairmus Platform users (minus Fairmus’ commission fee and any fees paid to collective management organisations) between the artists whose Artist Content the users listen to. This means that if an user listens in equal listening time the Artist Content of two artists, the subscription fees will be split between these two artists. Fairmus keeps record of and adds up the users listening time from the first second. Artists are distributed royalties based on the actual listening time of their Artist Content.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                Fairmus will provide you with a report on the listening counts of any Artist Content uploaded by you on quarterly basis, i.e. report on counts in first quarter (from January to March) will be provided in April, report on counts in second quarter (from April to May) will be provided in June, report on counts in third quarter (from June to August) will be provided in September) and report on counts in fourth quarter (from September to December) will be provided in January.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                You will be entitled to request the pay-out of your royalties once the minimum amount of EUR 10 (ten euros) has accumulated to your Artist Account. Note that Fairmus does not make any pay-outs automatically, i.e. you are required to submit a pay-out request via your Artist Account. As an exception to the above, Fairmus will make automatic and less than required minimum amount pay-outs in case your Artist Account is terminated.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                You will be provided more detailed information about the Fairmus’ royalty distribution system through your Artist Account. Fairmus reserves the right to unilaterally modify and update its royalty distribution system at any time.
            </Typography>

            <Typography variant="subtitle1" fontWeight="bold" pt={1}>Personal data</Typography>

            <Typography variant="subtitle1" pt={1}>
                We process your personal data only as permitted by applicable laws and regulations, including the EU Regulation 2016/679 of the European Parliament and of the Council of 27 April 2016 (GDPR). We only process personal data which you have provided to us in connection with contracts concluded between Fairmus and you under these T&Cs. We process and store your personal data only to the extent and as long as necessary for the performance of such contracts. With regard to processing your personal data, Fairmus Technologies OÜ acts as a data processor. In case of any questions please contact us via <a href="mailto:info@fairmus.com">info@fairmus.com</a>. If you feel that your right to privacy has been violated, you can submit a complaint with the EU supervisory authority of your permanent place of residence. You can find the contact details of supervisory authorities at <a href="https://edpb.europa.eu/about-edpb/board/members_en" target="_blank" rel="noreferrer noopener">https://edpb.europa.eu/about-edpb/board/members_en</a>.
            </Typography>

            <Typography variant="subtitle1" fontWeight="bold" pt={1}>Warranty disclaimer</Typography>

            <Typography variant="subtitle1" pt={1}>
                Fairmus Platform and Services are provided on “as is” and “as available” basis, without any express or implied warranty or representation of any kind. Fairmus and Fairmus’ licensors make no representations or warranties on satisfactory quality or fitness for a particular purpose of the Fairmus Platform or the Services or non-infringement of third party rights. Fairmus will not assume responsibility for any third party content available through the use of Fairmus Platform or Services.
            </Typography>

            <Typography variant="subtitle1" fontWeight="bold" pt={1}>Liability</Typography>

            <Typography variant="subtitle1" pt={1}>
                The use of the Fairmus Platform is at your own risk. Thus, Fairmus cannot be held responsible for any malicious program that may infect your computer or mobile equipment or any other hardware or software, nor for any loss or alteration of data resulting from the use of the Fairmus Platform. It is your responsibility to take all appropriate measures to protect your data, computer systems or software from contamination by possible viruses, trojan horses or, more generally, any computer programs that could compromise the security of the digital platform.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                Subject to the applicable legal and/or regulatory provisions, Fairmus cannot be held responsible for any direct or indirect damage linked to the use or the impossibility of using the Fairmus Platform and more generally, for any event linked to the Fairmus Platform such as, in particular, technical failures, breakdowns, interruptions, modifications to the Fairmus Platform.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                You are hereby kindly reminded that you remain solely responsible for the data (including any and all Artist Content) you communicate to Fairmus. Under no circumstances can Fairmus be held liable in case of error, omission or imprecision of the information that you have communicated. In particular, you are solely responsible for protecting your and your Representatives’ access credentials to access your Artist Account, such as your e-mail address and password. You are solely responsible for maintaining the confidentiality of your Artist Account details.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                To the fullest extent permitted by the applicable law, the liability of Fairmus is limited to the aggregate amount of any fees paid by you during the period of 1 year before the event giving rise to the liability of Fairmus took place.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                Nothing in these T&Cs limit or exclude the liability of Fairmus if such liability cannot be limited or excluded under applicable legal and/or regulatory provisions.
            </Typography>

            <Typography variant="subtitle1" fontWeight="bold" pt={1}>Indemnification</Typography>

            <Typography variant="subtitle1" pt={1}>
                You agree to indemnify and hold Fairmus harmless from and against all damages, losses, and expenses of any kind (including reasonable attorney fees and costs) arising out of or related to your breach of these T&Cs, your violation of any applicable legal and/or regulatory provisions, or any rights of third parties.
            </Typography>

            <Typography variant="subtitle1" fontWeight="bold" pt={1}>Confidentiality</Typography>

            <Typography variant="subtitle1" pt={1}>
                You acknowledge and agree that, during the term of these T&Cs and thereafter indefinitely, you are obliged to keep confidential, i.e. not use, forward, disclose or transfer in any format in the interests of yourself or any third parties, any confidential information or business and operation secrets of Fairmus, its cooperation partners or related persons. Confidential information is considered to be any and all information and details about Fairmus business and operations, irrespective of the form of communication and whether or not explicitly designated as confidential information. You are obliged to keep the confidential information, business and operation secrets in confidence regardless of whether you have learned about the information accidentally or not, i.e. also in case you have  become aware of the above specified information not for the purposes of and/or not as a result of performing the rights and obligations under these T&Cs. You are obliged to keep, the confidential information, business and operation secrets in confidence regardless of whether the information is documented or spoken. You are obliged to notify Fairmus immediately when you discover or have reasonable doubt that Fairmus’ confidential information, business or operation secrets have become available to third parties. Your confidentiality obligation established hereunder is valid for the applicable term of the T&Cs and for 10 years after the termination of the applicability of the T&Cs, after which the obligation becomes indefinite.
            </Typography>

            <Typography variant="subtitle1" fontWeight="bold" pt={1}>Term and termination</Typography>

            <Typography variant="subtitle1" pt={1}>
                These T&Cs apply from the moment you register your Artist Account and will continue to apply until your Artist Account is terminated by either you or Fairmus. You may terminate your Artist Account or your Artist Account may be terminated by Fairmus at any time with 30 calendar days prior notice. Fairmus reserves the right to terminate your Artist Account without prior notice if you fail to comply with any of the obligations arising from these T&Cs.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                From the moment your Artist Account is terminated, you will have no further right to access your Artist Account or Fairmus Platform or to use the Services. You acknowledge and agree that sections of these T&Cs that, either explicitly or by their nature, must remain in effect even after expiration or termination of your Artist Account, will survive expiration or termination. This includes regulation on all rights and licenses you have granted to Fairmus under these T&Cs.
            </Typography>

            <Typography variant="subtitle1" fontWeight="bold" pt={1}>Miscellaneous</Typography>

            <Typography variant="subtitle1" pt={1}>
                Fairmus may offer special promotions and additional features which will be subject to additional terms and conditions provided on the Fairmus Platform. Fairmus reserves the right to unilaterally modify and update the special promotions and additional features at any time.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                Neither Fairmus nor you will be liable in any amount for failure to perform any obligation under these T&Cs as the result of a Force Majeure Event.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                Our failure to exercise or enforce any provision or right of these T&Cs on one occasion does not mean that and will not prevent us from enforcing on subsequent occasions.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                If any provision of these T&Cs is held to be invalid, void or unenforceable for any reason, then that provision shall be deemed severable from these T&Cs and shall not affect the validity and enforceability of any remaining provisions or T&Cs.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                The relevant courts of Estonia will have jurisdiction in relation to these T&Cs, although we retain the right to bring proceedings against you for breach of these conditions in your country of residence or any other relevant country. The relevant law of Estonia will apply to these T&Cs.
            </Typography>

            <Typography variant="subtitle1" pt={1}>
                Updated December 2021
            </Typography>
        </>
    )
}

export default TermsContent;
