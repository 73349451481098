import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { RecordingMood } from '../../models/IRecording';

const moods: RecordingMood[] = [{
    label: 'Good Mood',
    value: 'good'
}, {
    label: 'Anger Management',
    value: 'chill'
}, {
    label: 'Workout & Gym',
    value: 'workout'
}, {
    label: 'Relax & Unwind',
    value: 'relax'
}, {
    label: 'Friday Night Dance Partyyyy',
    value: 'dance'
}, {
    label: 'Lounge & Dinner',
    value: 'lounge'
}, {
    label: 'Wonderwomen',
    value: 'wonderwomen'
}, {
    label: 'Rainy Day',
    value: 'rainy'
}, {
    label: 'Focus & Study',
    value: 'focus'
}, {
    label: 'Let\'s Get Creative',
    value: 'creative'
}];

interface MoodAutocompleteFieldProps {
    value: RecordingMood[] | null;
    label: string;
    error?: boolean;
    onChange: (event: React.ChangeEvent<{}>, value: RecordingMood[] | null) => void;
}

const MoodAutocompleteField = ({ value, label, error, onChange }: MoodAutocompleteFieldProps) => {
    return (
        <Autocomplete
            value={value ?? []}
            onChange={onChange}
            options={moods}
            multiple
            isOptionEqualToValue={(option, value) => option.value === value.value}
            getOptionDisabled={() => {
                if (value && value?.length >= 3) return true;
                return false;
            }}
            disableCloseOnSelect={true}
            sx={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} error={error} label={label} />}
        />
    )
}

export default MoodAutocompleteField;
