import React, { useState } from 'react'
import { IGenre } from '../models/IGenre';
import { AppActionType, useApp } from '../store/context/AppContext';
import useFirestore from './useFirestore';

const useGenres = () => {
    const [isFetching, setIsFetching] = useState(false);
    const { state, dispatch } = useApp();
    const { getAll, getById } = useFirestore();

    const genres = state.genres ?? [];
    const fetchGenres = async () => {
        // If we have already fetched genres, dont fetch again
        if (genres.length > 0) return;

        setIsFetching(true);
        const _genres = await getAll('genres');

        const data = _genres.docs.map((doc) => {
            const data: IGenre = doc.data() as IGenre;
            return {
                ...data,
                id: doc.id
            }
        });

        const sortedGenres = data.sort((a, b) => b.createdAt.toDate().valueOf() - a.createdAt.toDate().valueOf());

        dispatch({
            type: AppActionType.SET_GENRES,
            payload: sortedGenres
        })

        setIsFetching(false);
    }

    const fetchGenreById = async (id: string) => {
        // First lets check if we have it in store
        const genre = genres.find((genre) => genre.id === id);
        if (genre) return genre;

        // If not, lets fetch it
        try {
            const doc = await getById('genres', id);
            const data = doc.data() as IGenre;
            return {
                ...data,
                id: doc.id,
            };
        } catch (err) {
            console.error(`Fetching genres by id failed`, err);
            return null;
        }
    }

    return { genres, fetchGenres, fetchGenreById, isFetching };
}

export default useGenres
