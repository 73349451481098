import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import './index.css';
import app from './firebase'; // eslint-disable-line'
import { IntercomProvider } from 'react-use-intercom';
import { UserProvider } from './store/context/UserContext';
import { AppProvider } from './store/context/AppContext';
import { StatisticsProvider } from './store/context/StatisticsContext';
import { SnackbarProvider } from 'notistack';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: "https://09188441095b4fcea32df143eb8de624@o1012100.ingest.sentry.io/6259587",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

const INTERCOM_APP_ID = 'u94i55dq';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#443DF6',
        },
        secondary: {
            main: '#000033',
        },
        error: {
            main: '#E01E5A',
        },
        warning: {
            main: '#EF7F57',
        },
        info: {
            main: '#443DF6',
        },
        success: {
            main: '#36B37E',
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <IntercomProvider appId={INTERCOM_APP_ID}>
            <ThemeProvider theme={theme}>
                <UserProvider>
                    <AppProvider>
                        <StatisticsProvider>
                            <SnackbarProvider>
                                <BrowserRouter>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <CssBaseline />
                                        <App />
                                    </LocalizationProvider>
                                </BrowserRouter>
                            </SnackbarProvider>
                        </StatisticsProvider>
                    </AppProvider>
                </UserProvider>
            </ThemeProvider>
        </IntercomProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
