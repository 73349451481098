import { Navigate, Route, Routes } from "react-router-dom";
import AppAuthWrapper from "./components/AppAuthWrapper";
import Account from './pages/Account';
import Artists from "./pages/Artists";
import Home from './pages/Home';
import Login from './pages/Login';
import Recordings from "./pages/Recordings";
import RecordingsCreator from "./pages/Recordings/RecordingsCreator";
import Wallet from "./pages/Wallet";
import Albums from "./pages/Albums";
import Terms from "./pages/Terms";
import AlbumsCreator from "./pages/Albums/AlbumsCreator";
import { useUser } from "./store/context/UserContext";
import React, { useEffect, useState } from "react";
import AlbumsBatchCreator from "./pages/Albums/AlbumsBatchCreator";
import { hotjar } from "react-hotjar";
import { onSnapshot, query, where } from "firebase/firestore";
import useFirestore from "./hooks/useFirestore";
import { AppActionType, useApp } from "./store/context/AppContext";
import { useIntercom } from 'react-use-intercom';
import { formatUserImage } from "./utils";

const App = () => {

    /* Initialize hotjar */
    useEffect(() => {
        hotjar.initialize(2740402, 6);
    }, []);

    return (
        <AppAuthWrapper>
            <AppRoutes />
        </AppAuthWrapper>
    )
}

const AccountRoute = <Route path="account" element={<Account />} />;
const TermsRoute = <Route path="terms" element={<Terms />} />;

const AppRoutes = (): JSX.Element => {
    const { state: { data: user } } = useUser();
    const [hasInitializedOnSnapshot, setHasInitializedOnSnapshot] = useState(false);
    const { collectionRef } = useFirestore();
    const { dispatch } = useApp();
    const { boot, shutdown } = useIntercom();

    const routes = [];

    if (user) {
        if (!user.phoneVerified) {
            routes.push(AccountRoute);
        }
        if (user.phoneVerified && !user.termsAccepted) {
            routes.push(AccountRoute);
            routes.push(TermsRoute);
        }
        if (user.phoneVerified && user.termsAccepted) {
            routes.push(
                <Route path="/" element={<Home />} />,
                <Route path="wallet" element={<Wallet />} />,
                AccountRoute,
                <Route path="albums" element={<Albums />} />,
                // <Route path="albums/add" element={<AlbumsCreator />} />,
                <Route path="albums/create" element={<AlbumsBatchCreator />} />,
                <Route path="albums/edit/:id" element={<AlbumsCreator />} />,
                // <Route path="artists" element={<Artists />} />,
                <Route path="recordings" element={<Recordings />} />,
                // <Route path="recordings/add" element={<RecordingsCreator />} />,
                <Route path="recordings/edit/:id" element={<RecordingsCreator />} />,
                TermsRoute
            );
        }
    }

    useEffect(() => {
        if (user && user.phoneVerified && user.termsAccepted) {
            if (!hasInitializedOnSnapshot) {
                setHasInitializedOnSnapshot(true);

                /* Setup onSnapshot listener for recordings and albums for ownerId */
                const q = query(collectionRef('songs'), where('ownerId', '==', user.id));
                const unsubscribe = onSnapshot(q, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        if (change.type === 'modified') {
                            if (process.env.NODE_ENV === 'development') console.log('Updating song via onSnapshot', change.doc.id);
                            dispatch({
                                type: AppActionType.UPDATE_SONG,
                                payload: {
                                    id: change.doc.id,
                                    ...change.doc.data()
                                }
                            })
                        }
                    })
                }, (error) => {
                    console.log('snapshot error', error);
                });
                /* return () => unsubscribe(); */
            }
        }
    }, [user, hasInitializedOnSnapshot]);

    useEffect(() => {
        if (user != null) {
            let image = user.image;
            if (user.originalImageUrl) image = formatUserImage(user.id);
            boot({
                email: user.email ?? '',
                userId: user.id,
         //       createdAt: user.createdAt?.seconds.toString() ?? '',
                name: user.name ?? '',
                phone: user.phone,
                ...(image && {
                    avatar: {
                        type: 'avatar',
                        imageUrl: image
                    }
                })
            });
        }
        else shutdown();
    }, [user])

    const fallbackRoutePath = !user ? '/login' : !user.phoneVerified ? '/account' : !user.termsAccepted ? '/terms' : '/';
    return (
        <Routes>
            <Route path="login" element={<Login />} />
            {routes.map((route, i) => {
                return <React.Fragment key={i}>{route}</React.Fragment>;
            })}
            <Route path="*" element={<Navigate replace to={fallbackRoutePath} />} />
        </Routes>
    )
}

export default App
