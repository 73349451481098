import AddIcon from "@mui/icons-material/Add";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Navigation from "../../components/Navigation";
import AlbumsTable from "../../components/tables/AlbumsTable";
import useAlbums from "../../hooks/useAlbums";

const Albums = () => {
    const navigate = useNavigate();
    const { albums } = useAlbums();
    const [isTutorialDialogOpen, setIsTutorialDialogOpen] = useState(false);

    return (
        <>
            <Box display="flex" pb={12}>
                <Navigation />
                <Box p={{ xs: 2, md: 4 }} width="100%">
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} lg={8}>
                            <Typography variant="h3" fontWeight="bold">Albums</Typography>
                            <Typography
                                variant="body2"
                                color="primary"
                                sx={{ textDecoration: 'underline', cursor: 'pointer', width: 'max-content' }}
                                onClick={() => setIsTutorialDialogOpen(true)}
                            >
                                How to add an album?
                            </Typography>
                        </Grid>
                        {albums.length > 0 && (
                            <Grid item xs={12} lg={4}>
                                <Grid item>
                                    <Tooltip title="Create an album by uploading new recordings">
                                        <Button variant="contained" onClick={() => navigate('/albums/create')}><AddIcon /> Create new album or single</Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Box sx={{ mt: 4 }} />
                    <AlbumsTable />
                </Box>
            </Box>
            <Dialog
                open={isTutorialDialogOpen}
                onClose={() => setIsTutorialDialogOpen(false)}
            >
                <DialogTitle>
                    Adding an album
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The first step in order to add an album is to verify that you are the phonogram owner of the album and recordings. If you are not the phonogram owner, you are not allowed to upload.
                    </DialogContentText>
                    <br />
                    <DialogContentText>
                        In order to add a new album start by pressing <Link to="/albums/create">Create new album or single</Link> where you can create a new album by uploading new recordings.
                    </DialogContentText>
                    <br />
                    <DialogContentText>
                        Fill out all the required fields. If you haven't created an artist yet, you can do so in the "Artists" field. If you have created an artist before, or our database includes an artist you want to upload to the album, you can select it in the dropdown menu.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsTutorialDialogOpen(false)} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Albums
