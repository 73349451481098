import {Alert, Button, Checkbox, Chip, FormControlLabel, Grid, Link, TextField, Typography} from '@mui/material';
import { Box } from '@mui/system';
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import useFirestore from "../../hooks/useFirestore";
import { UserActionType, useUser } from "../../store/context/UserContext";
import FullscreenOverlayLoader from '../FullscreenOverlayLoader';
import MuiPhoneNumber from 'material-ui-phone-number';
import { ibanRegex } from '../../utils';

interface IFields {
    firstName?: string;
    lastName?: string;
    phone?: string;
    pin?: string;
    iban?: string | null;
    isni?: string | null;
    recordLabel?: boolean | undefined;
}

const AccountForm = () => {
    const { state, dispatch } = useUser();
    const { update } = useFirestore();

    const [fields, setFields] = useState<IFields>({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showPin, setShowPin] = useState(false);
    const [isRequestingCode, setIsRequestingCode] = useState(false);
    const [isConfirmingPin, setIsConfirmingPin] = useState(false);
    const [isPhoneError, setIsPhoneError] = useState(false);
    const [isPinError, setIsPinError] = useState(false);
    const [verificationId, setVerificationId] = useState('');
    const [isIBANError, setIsIBANError] = useState(false);

    const navigate = useNavigate();

    const phoneVerified = () => {
        return state.data?.phoneVerified;
    }

    const { data: user } = state;
    useEffect(() => {
        if (user) {
            const [first, last] = user?.name?.split(' ') ?? ['', ''];
            setFields((p) => ({ ...p, firstName: first, lastName: last, ...user }));
        }
    }, [user]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFields((p) => ({ ...p, [name]: value }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitted(false);
        setIsIBANError(false);
        if (!fields?.firstName && !fields?.lastName && !fields?.phone) return;
        if (fields.iban && !fields.iban.match(ibanRegex)) {
            setIsIBANError(true);
            return;
        }

        const data = {
            firstName: fields.firstName,
            lastName: fields.lastName,
            name: `${fields.firstName} ${fields.lastName}`,
            phone: fields.phone ?? null,
            iban: fields.iban ?? null,
            isni: fields.isni ?? null,
            recordLabel: fields.recordLabel ?? false
        }

        if (state.data?.id == null) return;
        await update('owners', state.data.id, data);

        const updatedUser = {
            ...state.data,
            ...data
        };

        dispatch({
            type: UserActionType.SET_USER,
            payload: updatedUser
        });

        setIsSubmitted(true);
    }

    const startPhoneVerification = async () => {
        setIsPhoneError(false);
        if (!fields?.phone) return;
        setIsRequestingCode(true);
        const fun = httpsCallable(getFunctions(), 'httpsSmsVerificationStart');
        const response: any = await fun({ phone: fields.phone });
        const id = response.data.verificationId;

        if (id === null) {
            setIsPhoneError(true);
            setIsRequestingCode(false);
            return;
        }

        setVerificationId(id);
        setShowPin(true);
        setIsRequestingCode(false);
    }

    const handlePinVerification = async () => {
        setIsPinError(false);
        if (!fields?.pin) return;
        setIsConfirmingPin(true);

        const fun = httpsCallable(getFunctions(), 'httpsSmsVerificationPin');
        const response: any = await fun({ verificationId, pin: fields.pin });
        const isVerified = response.data.verified;

        if (!isVerified) {
            setIsPinError(true);
            setIsConfirmingPin(false);
            return;
        }

        const data = {
            ...state.data, // make sure that the user is updated
            ...(state.data?.firstName && state.data?.lastName && { name: `${state.data.firstName} ${state.data.lastName}` }),
            phoneVerified: true,
            phone: fields.phone,
        }
        console.log('data', data)

        if (state.data?.id == null) return;
        await update('owners', state.data.id, data);

        const updatedUser = {
            ...state.data,
            ...data
        };

        dispatch({
            type: UserActionType.SET_USER,
            payload: updatedUser
        });

        if (!updatedUser.termsAccepted) navigate('/terms');

        setIsConfirmingPin(false);
        setShowPin(false);
    }

    return (
        <>
            {(isRequestingCode || isConfirmingPin) && <FullscreenOverlayLoader />}
            {isSubmitted && <Alert severity="success" sx={{ mb: 4 }}>Account info updated</Alert>}
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name="firstName"
                            label="First Name"
                            size="small"
                            value={fields?.firstName ?? ''}
                            onChange={handleInputChange}
                            sx={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="lastName"
                            label="Last Name "
                            size="small"
                            value={fields?.lastName ?? ''}
                            onChange={handleInputChange}
                            sx={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="isni"
                            value={fields?.isni ?? ''}
                            onChange={handleInputChange}
                            label="ISNI"
                            size="small"
                            sx={{ width: '100%' }}
                            helperText="Optional, but highly recommended"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="iban"
                            label="IBAN"
                            size="small"
                            value={fields?.iban ?? ''}
                            helperText="Optional, required only on payout"
                            onChange={handleInputChange}
                            sx={{ width: "100%" }}
                            error={isIBANError}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox
                                onChange={(_, value) => setFields((p) => ({ ...p, recordLabel: value }))}
                                checked={fields?.recordLabel ?? false}
                                size="small"
                            />}
                            label="This account is represented by a record label"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <MuiPhoneNumber
                                    name="phone"
                                    label="Phone"
                                    size="small"
                                    variant="outlined"
                                    defaultCountry='ee'
                                    regions={['america', 'europe']}
                                    preferredCountries={['ee', 'fi', 'se', 'lv', 'lt', 'ru']}
                                    value={fields?.phone ?? ''}
                                    onChange={(value) => {
                                        if (typeof value === 'string') {
                                            setFields((p) => ({ ...p, phone: value }))
                                        }
                                    }}
                                    autoFormat={false}
                                    error={isPhoneError}
                                    disabled={phoneVerified() || showPin}
                                    disableDropdown={phoneVerified() || showPin}
                                    sx={{ width: '100%', 'svg': { maxWidth: 24, maxHeight: 24 } }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Chip label={phoneVerified() ? 'Verified' : 'Not verified'} color={phoneVerified() ? 'success' : 'error'} variant="outlined" />
                            </Grid>
                            {!showPin && !phoneVerified() && fields?.phone && fields?.phone?.length > 8 && (
                                <Grid item xs={6} >
                                    <Button onClick={() => startPhoneVerification()} variant="contained">REQUEST CODE</Button>
                                </Grid>
                            )}
                            {showPin && (
                                <>
                                    <Grid item xs={6} >
                                        <TextField
                                            name="pin"
                                            label="PIN"
                                            size="small"
                                            value={fields?.pin ?? ''}
                                            onChange={handleInputChange}
                                            sx={{ width: "100%" }}
                                            error={isPinError}
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Button onClick={() => handlePinVerification()} variant="contained">CONFIRM CODE</Button>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {isIBANError && (
                    <>
                        <Box my={2} />
                        <Typography variant="body2" color="error">Invalid IBAN</Typography>
                    </>
                )}
                <Box my={4} />
                {!showPin && <Button variant="outlined" type="submit">Save</Button>}

                <Typography sx={{marginTop:"40px"}} variant="body2" color="textSecondary">Do you need to delete your account? Please contact us: <br /></Typography>
                <Link href="mailto:info@fairmus.com" variant="body2" color="primary" style={{ textDecoration: 'none' }}>info@fairmus.com</Link>
            </form>
        </>
    )
}

export default AccountForm
