import React, { useState } from 'react'
import { ITransaction, TargetType, TransactionStatus, TransactionType } from '../models/ITransaction';
import { AppActionType, useApp } from '../store/context/AppContext';
import { DocumentData, getDocs, query, where } from "firebase/firestore";
import { IRecording } from "../models/IRecording";
import useFirestore from "./useFirestore";
import { useUser } from "../store/context/UserContext";
import { IWallet } from '../models/IApp';

const generateTransactionTotal = (documents: DocumentData[], target: TargetType) => {
    let total = 0;
    let pending = 0;
    let paid = 0;
    for (const document of documents) {
        const transaction = document.data();
        if (transaction.target === target) {
            if (transaction.type === TransactionType.ROYALTIES_IN) total += transaction.amount;
            if (transaction.status === TransactionStatus.Failed) total += transaction.amount;
            if (transaction.type === TransactionType.ROYALTIES_OUT) {
                if (transaction.status === TransactionStatus.Pending) pending += transaction.amount;
                total -= transaction.amount;

                if(transaction.status === TransactionStatus.Completed) {
                    paid += transaction.amount;
                }
            }
        }
    }

    return { total, pending, paid };
}

const roundDecimals = (num: number) => {
    if (num === 0) return 0;
    return Math.round(num * 100) / 100;
}

const useWallet = () => {
    const [isFetching, setIsFetching] = useState(false);
    const { state, dispatch } = useApp();
    const { collectionRef } = useFirestore();
    const { state: { data: user } } = useUser();

    const transactions = state.transactions ?? [];
    const wallet: IWallet = state.wallet ?? { amount: 0, pending: 0, eelTotal: 0, eelPending: 0, eauTotal: 0, eauPending: 0 };

    const fetchTransactions = async ({ force = false }: { force?: boolean } = {}) => {
        // If we already have transactions, dont fetch again
        // We can bypass this check if we want to force a fetch
        if (!force && transactions.length > 0) return transactions;
        setIsFetching(true);

        const q = query(collectionRef('transactions'), where('ownerId', '==', user?.id));
        const snapshot = await getDocs(q);

        const { total: walletTotal, pending: walletPending, paid: walletPaid } = generateTransactionTotal(snapshot.docs, TargetType.OWNER);
        const { total: eelTotal, pending: eelPending } = generateTransactionTotal(snapshot.docs, TargetType.EEL);
        const { total: eauTotal, pending: eauPending } = generateTransactionTotal(snapshot.docs, TargetType.EAU);

        const data = snapshot.docs.map((doc) => ({ ...doc.data() as ITransaction, id: doc.id }));

        const sortedTransactions = data.sort((a, b) => b.createdAt.toDate().valueOf() - a.createdAt.toDate().valueOf());

        setIsFetching(false);
        dispatch({
            type: AppActionType.SET_TRANSACTIONS,
            payload: sortedTransactions
        })

        dispatch({
            type: AppActionType.SET_WALLET_AMOUNT,
            payload: {
                amount: roundDecimals(walletTotal),
                pending: roundDecimals(walletPending),
                paid: roundDecimals(walletPaid),
                eauTotal: roundDecimals(eauTotal),
                eauPending: roundDecimals(eauPending),
                eelTotal: roundDecimals(eelTotal),
                eelPending: roundDecimals(eelPending)
            }
        })
    }

    const fetchTransactionDetails = async(startDate) => {
        const q = query(collectionRef('songTransactions'), where('ownerId', '==', user?.id), where('periodFrom', '==', startDate));
        const snapshot = await getDocs(q);
        const transactions = [];
        const totals = {songName: '', album: '', artists: '', periodFrom: '', periodTo: '', app: '', source: '',  totalTimeListened: 0, fullSubscriptionShare: 0, feeShare: 0, fonoShare: 0, eelShare: 0, eauShare: 0};

        snapshot.docs.forEach((doc) => {
            const {songName, album, artists, periodFrom, periodTo, app, fullSubscriptionShare, source, totalTimeListened, userId} = doc.data();
            // if(userId === '9eZAqdC3ntWtwXY9c7b6qaklzgT2') {
            const feeShare = fullSubscriptionShare * 2.99 / 100;
            const fonoShare = fullSubscriptionShare * (app === 'ios' ? 0.5: 0.94) / 100;
            const eelShare = fullSubscriptionShare * 0.3 / 100;
            const eauShare = fullSubscriptionShare * 0.3 / 100;
            const record =  {songName, album: album?.name, artists, periodFrom, periodTo, app, source, totalTimeListened, fullSubscriptionShare, feeShare, fonoShare, eelShare, eauShare};
            transactions.push(record);
            totals.totalTimeListened += totalTimeListened;
            totals.fullSubscriptionShare += fullSubscriptionShare;
            totals.feeShare += feeShare;
            totals.fonoShare += fonoShare;
            totals.eelShare += eelShare;
            totals.eauShare += eauShare;
            // }
        });
        transactions.push(totals);

        return transactions;
    };

    return { wallet, transactions, fetchTransactions, fetchTransactionDetails, isFetching };
}

export default useWallet
