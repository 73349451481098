import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { Alert, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material";
import { Box } from '@mui/system';
import { useEffect } from "react";
import useArtists from '../../hooks/useArtists';
import useRecordings from "../../hooks/useRecordings";
import { IArtist } from '../../models/IArtist';
import { IRecording } from '../../models/IRecording';
import { useUser } from "../../store/context/UserContext";
import AvatarRowItem from '../AvatarRowItem';
import ListDropdown from '../ListDropdown';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

let yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

const RecordingsTable = () => {
    const { recordings, fetchRecordings, isFetching } = useRecordings();
    const { state: { data: user } } = useUser();
    const { artists, fetchArtistById } = useArtists();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) fetchRecordings();
    }, []);

    useEffect(() => {
        if (recordings.length > 0) {
            for (const recording of recordings) {
                for (const artistId of recording.artists) {
                    (async () => fetchArtistById(artistId))();
                }
            }
        }
    }, [recordings])

    if (isFetching) return (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
        </Box>
    );

    if (!isFetching && recordings.length <= 0) return (
        <Alert severity="warning" sx={{ alignItems: 'center', maxWidth: 'max-content' }}>
            You currently don't have any recordings to show. Start by creating an album <Link to="/albums/create">here</Link>.
        </Alert>
    );

    const getRowArtists = (row: IRecording): [IArtist[]] => {
        const _artists: IArtist[] = [];
        for (const artistId of row.artists) {
            const artist = artists.find(artist => artist.id === artistId);
            if (artist) _artists.push(artist);
        }

        return [_artists];
    }

    const getRows = () => {
        return recordings.map((recording: IRecording) => {
            const [artists] = getRowArtists(recording);

            const isApproved = recording.hasOwnProperty('approved') && !recording.approved ? false : true;
            const isUploading = (recording.createdAt.toDate() > yesterday && !recording.url) ?? false;
            const hasMoodsField = recording.hasOwnProperty('moods');

            return {
                id: recording.id,
                name: recording.name,
                isrc: recording.isrc,
                artists: artists,
                media: recording.url,
                recordingYear: recording.recordingYear,
                createdAt: recording.createdAt.toDate().toLocaleDateString(),
                approved: isApproved,
                hasMoodsField,
                edit: navigate,
                isUploading
            }
        })
    }

    return (
        <DataGrid
            rows={getRows()}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableColumnMenu={true}
            disableColumnSelector={true}
            isRowSelectable={() => false}
            isCellEditable={() => false}
            disableSelectionOnClick={true}
            autoHeight={true}
        />
    )
};

const columns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        sortable: false,
        minWidth: 175,
        renderCell: (cell) => {
            if (cell.row.isUploading) {
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Tooltip title="Uploading recording">
                            <CircularProgress size={20} sx={{ marginRight: 2 }} />
                        </Tooltip>
                        {cell.value}
                    </Box>
                )
            }
            return cell.value;
        }
    },
    {
        field: 'isrc',
        headerName: 'ISRC',
        flex: 1,
        minWidth: 100,
        sortable: false
    },
    {
        field: 'artists',
        headerName: 'Artists',
        renderCell: (cell) => {
            const artists: IArtist[] = cell.value;
            const isUploading = cell.row.isUploading;
            const artistsMap = artists.map((artist, i) => (
                <Box sx={{ ...(isUploading && { opacity: 0.2 }) }} key={i}>
                    <AvatarRowItem name={artist.name ?? ''} img={artist.img && `${artist.img}?alt=media`} />
                </Box>
            ));
            if (artists.length === 1) return artistsMap;
            return (
                <ListDropdown label={`View ${artists.length} artists`}>
                    <>{artistsMap}</>
                </ListDropdown>
            )
        },
        flex: 1,
        minWidth: 160,
        sortable: false
    },
    {
        field: 'media',
        headerName: 'Media',
        renderCell: (cell) => {
            const mediaUrl = cell.value;
            const isUploading = cell.row.isUploading;

            if (isUploading && !mediaUrl) return <Typography variant="body2" color="textSecondary">Uploading audio...</Typography>
            if (!mediaUrl) return <Typography variant="body2" color="textSecondary">No audio available</Typography>;
            return (
                <audio controls style={{ maxWidth: 220, maxHeight: 40 }}>
                    <source src={`${mediaUrl}?alt=media`} />
                </audio>
            )
        },
        width: 220,
        minWidth: 220,
        flex: 1,
        sortable: false
    },
    {
        field: 'recordingYear',
        headerName: 'Recording year',
        width: 130,
        sortable: false
    },
    {
        field: 'createdAt',
        headerName: 'Created',
        width: 100,
        sortable: false
    },
    {
        field: 'approved',
        headerName: 'Approved',
        renderCell: (cell) => {
            const approved = cell.value;
            const hasMoodsField = cell.row.hasMoodsField;
            if (!approved) return (
                <Tooltip title="Under review">
                    <CancelIcon color="error" />
                </Tooltip>
            )
            if (approved && !hasMoodsField) return (
                <Tooltip title="Please add moods to recording">
                    <WarningIcon color="warning" />
                </Tooltip>
            )
            return <CheckCircleIcon color="success" />
        },
        width: 100,
        sortable: false
    },
    {
        field: 'edit',
        headerName: 'Edit',
        renderCell: (cell) => {
            const id = cell.id;
            const navigate = cell.value;
            const isUploading = cell.row.isUploading;
            if (isUploading) return '';
            return (
                <IconButton onClick={() => navigate(`/recordings/edit/${id}`)}>
                    <EditIcon sx={{ width: 20, height: 20 }} />
                </IconButton>
            )
        },
        width: 70,
        sortable: false,
    }
];

export default RecordingsTable;
