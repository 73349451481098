import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { getFunctions, httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react';
import { IPlace } from '../../models/IArtist';

let delayTimer: any;
function timeoutSearch(text: string, cb: any) {
    clearTimeout(delayTimer);
    delayTimer = setTimeout(cb, 300);
}

interface CountryOptionType extends IPlace {
    inputValue: string;
}

interface PlacesAutocompleteFieldProps {
    value?: CountryOptionType | CountryOptionType[] | null;
    label: string;
    searchType: string;
    optionLimit?: number;
    multiple?: boolean;
    error?: boolean;
    onValueChange: (value: IPlace | IPlace[]) => void;
}

const PlacesAutocompleteField = ({ value: controlledValue, searchType, optionLimit = 3, multiple = false, error, label, onValueChange }: PlacesAutocompleteFieldProps) => {
    const [value, setValue] = useState<CountryOptionType | CountryOptionType[] | null>(multiple ? [] : null);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<CountryOptionType[]>([]);

    // If we have set values from parent, set it to local state
    useEffect(() => {
        if (multiple && Array.isArray(value) && Array.isArray(controlledValue)) {
            if (controlledValue && controlledValue.length > value.length) {
                if (value.length >= optionLimit) return;
                setValue(controlledValue);
            }
        }
    }, [controlledValue]);

    const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: CountryOptionType | CountryOptionType[] | null, reason: string) => {
        // If the user wants to clear the whole input field
        if (reason === 'clear') {
            setValue([]);
            return;
        }

        const [last] = Array.isArray(newValue) ? newValue.slice(-1) : [newValue];

        // If we are entering a new option by pressing the 'ENTER' key
        if (typeof last === 'string') {
            return;
        }

        // If we are removing a selected option
        if (reason === 'removeOption') {
            if (!newValue || typeof newValue === 'string' || !Array.isArray(newValue)) {
                setValue([]);
                return;
            }
            setValue(newValue as CountryOptionType[]);
            return;
        }

        // If the clicked option already exists in 'options' we have to add it to 'value'

        if (options.find((o) => o.id === last?.id)) {
            if (Array.isArray(value) && multiple) {
                // @ts-ignore-next-line
                setValue((prevState) => {
                    if (prevState && Array.isArray(prevState)) return [...prevState, last];
                    return [last];
                });
                return;
            }
            setValue(last);
        }
    }

    const handleInputChange = (query: string) => {
        // Only start search on the second character
        if (!query || query.length <= 1) return;

        setIsLoading(true);
        timeoutSearch(query, async () => {
            const fun = httpsCallable(getFunctions(), 'httpsGooglePlacesSearch');

            setOptions([]);
            const result: any = await fun({
                searchString: query,
                type: searchType,
            });

            const resultOptions = options ?? [];
            if (result.data) {
                result.data.forEach((place: IPlace) => {
                    const exists = resultOptions.find((o) => o.id === place.id);
                    if (exists) return;
                    resultOptions.push({
                        inputValue: place.name ?? '',
                        id: place.id,
                        secondary_text: place.secondary_text,
                    })
                });
            }
            setOptions(resultOptions);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        if (multiple && Array.isArray(value)) {
            onValueChange(value.map((el) => {
                return {
                    id: el.id,
                    name: el.inputValue
                }
            }));
        } else if (value && !Array.isArray(value)) {
            onValueChange({
                id: value.id,
                name: value.inputValue
            });
        }
    }, [value]);

    return (
        <React.Fragment>
            <Autocomplete
                value={value}
                onChange={handleChange}
                onInputChange={(event, value) => handleInputChange(value)}
                options={options}
                loading={isLoading}
                getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.inputValue;
                }}
                getOptionDisabled={() => Array.isArray(value) && value.length >= optionLimit}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                multiple={multiple}
                renderOption={(props, option) => (
                    <li {...props}>
                        {option.inputValue} {option.secondary_text && `(${option.secondary_text})`}
                    </li>
                )}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} error={error} label={label} />}
            />
        </React.Fragment>
    );
}

export default PlacesAutocompleteField;
