import { getFunctions, httpsCallable } from '@firebase/functions';
import { Grid, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import { getDocs, query, where } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import firebase from '../../firebase';
import useFirestore from '../../hooks/useFirestore';
import { IArtist } from '../../models/IArtist';
import { useUser } from '../../store/context/UserContext';
import { getImageUrl, uploadImage } from '../../utils';
import FullscreenOverlayLoader from '../FullscreenOverlayLoader';
import PlacesAutocompleteField from './PlacesAutocompleteField';

let delayTimer: any;
function timeoutSearch(text: string, cb: any) {
    clearTimeout(delayTimer);
    delayTimer = setTimeout(cb, 300);
}
export interface ArtistOptionType extends IArtist {
    inputValue: string;
}

interface ArtistAutocompleteFieldProps {
    value: ArtistOptionType[];
    label: string;
    error?: boolean;
    helperText?: string;
    onValueChange: (value: IArtist[]) => void;
}

const ArtistAutocompleteField = ({ value: controlledValue, error, label, helperText, onValueChange }: ArtistAutocompleteFieldProps) => {
    const [value, setValue] = useState<ArtistOptionType[]>([]);
    const [options, setOptions] = useState<ArtistOptionType[]>([]);
    const [dialogValue, setDialogValue] = useState<IArtist>({});
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoadingArtists, setIsLoadingArtists] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [open, toggleOpen] = useState(false);
    const { add, update, collectionRef } = useFirestore();
    const { enqueueSnackbar } = useSnackbar();

    // If we have set values from parent, set it to local state
    useEffect(() => {
        if (controlledValue && controlledValue.length > 0 && value.length <= 0) {
            setValue(controlledValue);
        };
    }, [controlledValue]);

    const { state: { data: user } } = useUser();

    /* Artist image upload */
    const [artistImagePreview, setArtistImagePreview] = useState<string | null>(null);
    const onDrop = useCallback((acceptedFiles) => {
        const [image] = acceptedFiles;
        const previewImage = URL.createObjectURL(image);
        setArtistImagePreview(previewImage);

        // Set file
        setImageFile(image);

    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpeg, image/png, image/jpg, image/webp' });

    const handleClose = () => {
        setDialogValue({});
        toggleOpen(false);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { name, value } = event.target;
        setDialogValue((p) => ({ ...p, [name]: value }));
    }

    const setFields = (name: string, value: any) => {
        setDialogValue((p) => ({ ...p, [name]: value }));
    }

    const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: (string | ArtistOptionType)[], reason: string) => {
        // If the user wants to clear the whole input field
        if (reason === 'clear') {
            setValue([]);
            return;
        }

        const [last] = Array.isArray(newValue) ? newValue.slice(-1) : [newValue];

        // If we are entering a new option by pressing the 'ENTER' key
        if (typeof last === 'string') {
            setTimeout(() => {
                toggleOpen(true);
                setDialogValue((p) => ({ ...p, name: last }));
            });
            return;
        }

        // If we are removing a selected option
        if (reason === 'removeOption') {
            if (!newValue || typeof newValue === 'string' || !Array.isArray(newValue)) {
                setValue([]);
                return;
            }
            setValue(newValue as ArtistOptionType[]);
            return;
        }

        // If the clicked option already exists in 'options' we have to add it to 'value'
        if (options.find((o) => o.id === last.id)) {
            setValue((p) => [...p, last]);
            return;
        }

        toggleOpen(true);
        setDialogValue((p) => ({ ...p, name: last.name }));
    }

    const handleSearchArtists = async (query: string) => {
        // Only start search on the second character
        if (!query || query.length <= 1) return;

        setIsLoadingArtists(true);
        timeoutSearch(query, async () => {
            const response: any = await httpsCallable(getFunctions(), 'httpsSearch')({ query });
            if (!response) return;

            const results = response.data.results;
            const artists = results.find((r: any) => r.index === 'artists');

            const resultOptions: ArtistOptionType[] = [];
            for (const hit of artists.hits) {
                if (Boolean(!value.find((el) => el.id === hit.objectID))) {
                    if (Boolean(!resultOptions.find((el) => el.id === hit.objectID))) {
                        resultOptions.push({
                            id: hit.objectID,
                            img: hit.img,
                            name: hit.name,
                            inputValue: hit.name,
                        });
                    }
                }
            }
            setIsLoadingArtists(false);
            setOptions(resultOptions);
        });
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!user) return;

        let errors = [];
        if (!dialogValue.name) errors.push('name');
        if (!dialogValue.country) errors.push('country');
        if (!imageFile) errors.push('imageFile');
        if (errors.length > 0) {
            setErrors(errors);
            enqueueSnackbar('Please fill out all required fields', { variant: 'error' });
            return;
        } else setErrors([]);


        setIsSubmitting(true);

        const artistData = {
            name: dialogValue.name,
            createdAt: new Date(),
            // approved: false, We want the artist to go into agolia as soon as it's created to show it in dropdown
            country: dialogValue.country ?? null,
            city: dialogValue.city ?? null,
            ownerId: user.id
        };

        // Check if an artist with this name already exists
        try {
            const q = query(collectionRef('artists'), where('name', '==', dialogValue.name));
            const artists = await getDocs(q);
            if (!artists.empty) {
                enqueueSnackbar('Artist with this name already exists, please choose from dropdown', { variant: 'error' });
                setIsSubmitting(false);
                return;
            }
        } catch (err) {
            console.log('Fetching artists failed', err);
        }

        try {
            // Create artist
            const artist = await add('artists', artistData);

            // Upload image
            await uploadImage(artist.id, 'artists', imageFile as File);
            const imageUrl = getImageUrl(artist.id, 'artists', imageFile as File);
            // Update artist with uploaded image
            await update('artists', artist.id, {
                img: imageUrl
            });

            // Add artist to input field
            setValue((p) => [...p, {
                ...dialogValue,
                name: dialogValue.name ?? '',
                id: artist.id,
                img: imageUrl,
                inputValue: dialogValue.name ?? '',
            }]);

            // Reset form values
            setIsSubmitting(false);
            setDialogValue({});
            setImageFile(null);
            handleClose();
        } catch (err) {
            enqueueSnackbar('Creating new artist failed', { variant: 'error' });
            setIsSubmitting(false);
        }
    };

    useEffect(() => onValueChange(value as IArtist[]), [value]);

    const isError = (name: string) => errors?.includes(name) ?? false;

    return (
        <React.Fragment>
            <Autocomplete
                value={value}
                onInputChange={(event, value) => handleSearchArtists(value)}
                onChange={handleChange}
                filterOptions={(options, params) => {
                    const filtered = options;

                    if (params.inputValue !== '' && !isLoadingArtists) {
                        filtered.unshift({
                            inputValue: `Add "${params.inputValue}"`,
                            name: params.inputValue,
                            id: `${Math.random()}`,
                        });
                    }

                    return filtered;
                }}
                options={options}
                getOptionDisabled={(option) => Boolean(value.find((v) => v.id === option.id))}
                getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.inputValue;
                }}
                loading={isLoadingArtists}
                loadingText="Loading artists..."
                multiple
                renderOption={(props, option) => (
                    <li {...props}>
                        {option.img && <img src={option.img && `${option.img}?alt=media`} alt={option.inputValue} style={{ width: 24, height: 24, marginRight: 6, borderRadius: 2 }} />}
                        {option.inputValue}
                    </li>
                )}
                sx={{ width: '100%' }}
                freeSolo
                renderInput={(params) => <TextField {...params} error={error} helperText={helperText} label={label} />}
            />
            <Dialog open={open} onClose={handleClose} fullWidth>
                <form onSubmit={handleSubmit}>
                    <DialogTitle>Add a new artist</DialogTitle>
                    <DialogContent>
                        <DialogContentText>By adding a new artist you are solely responsible for any actions performed under the name of said artist.</DialogContentText>
                    </DialogContent>
                    <DialogContent>
                        <TextField
                            autoFocus
                            name="name"
                            value={dialogValue.name}
                            error={isError('name')}
                            onChange={handleInputChange}
                            label="Full name"
                            sx={{ width: '100%' }}
                        />
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item xs={12} lg={6}>
                                <PlacesAutocompleteField
                                    searchType='countries'
                                    error={isError('country')}
                                    onValueChange={(country) => setFields('country', country)}
                                    label="Country"
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <PlacesAutocompleteField
                                    searchType='cities'
                                    error={isError('city')}
                                    onValueChange={(city) => setFields('city', city)}
                                    label="City"
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ my: 4 }} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body2" fontWeight="bold">Artist image</Typography>
                                <Typography variant="body2">Use a high quality image, preferrably a press photo.</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div {...getRootProps()} style={{
                                    width: '100%',
                                    maxWidth: '200px',
                                    height: '200px',
                                    backgroundColor: '#FAFAFA',
                                    border: `${(artistImagePreview && !isError('imageFile')) ? '0px' : '1px'} solid ${isError('imageFile') ? 'red' : '#EAEAEA'}`,
                                    borderRadius: (artistImagePreview && !isError('imageFile')) ? 0 : 8,
                                    padding: '16px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'relative',
                                }}>
                                    <input {...getInputProps()} multiple={false} />
                                    {
                                        isDragActive ?
                                            <Typography textAlign="center">Drop the image here ...</Typography> :
                                            <Typography textAlign="center">Drag 'n' drop an image here, or click to select image</Typography>
                                    }
                                    {artistImagePreview && <img src={artistImagePreview} alt="artist image preview" style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        backgroundColor: '#FAFAFA',
                                    }} />}
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
                {open && isSubmitting && <FullscreenOverlayLoader />}
            </Dialog>
        </React.Fragment>
    );
}

export default ArtistAutocompleteField;
